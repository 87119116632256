export class ApiConstants {
  static readonly BASEURL = '/vi-api/dataservice/v1/';
  static readonly FILEAPIBASEURL = '/fileapi/v1/FileManagement/';
  static readonly DASHBOARD = 'Dashboard/';
  static readonly CAMERAPROFILE = 'CameraProfile/';
  static readonly MEDIADATA = 'MediaData/';
  static readonly EVENT = 'Event/';
  static readonly ZONECONFIGURATION = 'ZoneConfiguration/';
  static readonly ZONECONFIGHISHTORY = 'ZoneConfigHistory/';
  static readonly ZONESETTING = 'ZoneSetting';
  static readonly GATEWAY = 'Gateway/';
  static readonly CAMERA = 'Camera/';
  static readonly USERSETTING = 'UserSettings/';
}
