<slb-navigation-framework
  #framework
  applicationLogo="assets/logoslb.svg"
  [applicationName]="'Agora  &nbsp; | &nbsp; Vision Intelligence'"
  [displayViewModeButtons]="false"
  [hasSidenav]="false"
  [notifications]="true"
  [isDelfiApp]="false"
  [showBackButton]="showBackButton"
  [primaryNavigationItems]="primaryNavItems"
  (primaryNavItemSelected)="primaryNavigationSelected($event)">
  <slb-framework-toolbar>
    <ng-content *ngTemplateOutlet="toolbar"></ng-content>
  </slb-framework-toolbar>
  <slb-framework-content>
    <ng-content *ngTemplateOutlet="content"></ng-content>
  </slb-framework-content>

  <slb-framework-page-header
    *ngIf="showHeader && authorized"
    [showBackButton]="showBackButton"
    [pageTitle]="pageTitle"
    [ngClass]="{ 'new-page-header-height': panelOpenState, 'no-panel-header': isZoneDefinationScreen }">
    <div
      class="time-zone"
      *ngIf="!pageTitle?.includes('Dashboard') && !pageTitle?.includes('Analytics') && !pageTitle?.includes('definition')">
      <div class="top-icon">
        <mat-icon
          svgIcon="oil-bitumen"
          *ngIf="cameraWorkflow.length"
          class="icon-width"
          [ngClass]="{
            active: cameraStatus === '0',
            inactive: cameraStatus === '1',
            disconnected: cameraStatus === '2'
          }"></mat-icon>
      </div>
      <mat-form-field slbFormField [matTooltip]="currentZoneArea + ' ' + currentTimezone" class="camera">
        <input matInput readonly aria-readonly="true" [(ngModel)]="currentZoneArea + ' ' + currentTimezone" id="agora_vi_timezone_input" />
      </mat-form-field>
    </div>
    <div class="top-icon" *ngIf="pageTitle?.includes('definition')">
      <mat-icon
        svgIcon="oil-bitumen"
        class="icon-width"
        [ngClass]="{
          active: cameraStatus === '0',
          inactive: cameraStatus === '1',
          disconnected: cameraStatus === '2'
        }"></mat-icon>
    </div>
    <div class="zone-page-title-camera-container" *ngIf="pageTitle?.includes('definition')">
      <mat-icon
        svgIcon="oil-bitumen"
        class="zone-camera-icon-width"
        [ngClass]="{
          active: cameraStatus === '0',
          inactive: cameraStatus === '1',
          disconnected: cameraStatus === '2'
        }"></mat-icon>
      <p class="zone-page-title-camera-label">Site: {{ rigName }}</p>
    </div>
    <div class="country" *ngIf="showCountry">
      <mat-form-field slbFormField>
        <input matInput readonly aria-readonly="true" [(ngModel)]="country" id="agora_vi_country_input" />
      </mat-form-field>
    </div>

    <button
      class="zone-config-btn"
      *ngIf="cameraName && !pageTitle?.includes('definition')"
      slb-button
      (click)="openZoneConfigurationPopUp()">
      Zone
    </button>

    <button
      class="zone-config-btn"
      *ngIf="pageTitle?.includes('definition')"
      (click)="requestImage()"
      [disabled]="disableRequestImage()"
      slb-button>
      Request an image
    </button>
    <div>
      <button (click)="panelOpenState = !panelOpenState" class="toggle-icon" *ngIf="cameraName && !pageTitle?.includes('definition')">
        <mat-icon svgIcon="arrow-down-5" *ngIf="panelOpenState" class="down-icon" id="agora_vi_arrow_down_icon"></mat-icon>
        <mat-icon svgIcon="arrow-up-5" *ngIf="!panelOpenState" id="agora_vi_arrow_up_icon"></mat-icon>
      </button>
      <div class="camera-details">
        <app-camera-profile-header *ngIf="panelOpenState"></app-camera-profile-header>
      </div>
    </div>
  </slb-framework-page-header>

  <ng-template #toolbar>
    <span class="last-refreshed" *ngIf="authorized"> Last refresh at: {{ refreshedDate | date : 'medium' }}</span>
    <span>
      <mat-icon svgIcon="refresh" *ngIf="authorized" class="refresh-icon" id="agora_vi_refresh_icon" (click)="navigateToSelf()"></mat-icon>
    </span>
    <slb-logout *ngIf="userName" [userName]="userName" (logout)="logout()">
      <p class="toolbar-email">{{ userEmail }}</p>
      <mat-slide-toggle class="toggle" *ngIf="isAdmin" [(ngModel)]="isAllEvents" (change)="showAllEvents()"
        >Show all events</mat-slide-toggle
      >
      <p class="user-setting" *ngIf="routeUrl && !routeUrl.includes('unauthorized')">
        User settings
        <mat-icon (click)="redirectUserSettings()" class="setting-icon" svgIcon="settings"></mat-icon>
      </p>
      <p class="toolbar-info">Application version: 2.2.1</p>
    </slb-logout>
  </ng-template>

  <ng-template #content>
    <router-outlet></router-outlet>
  </ng-template>
</slb-navigation-framework>
