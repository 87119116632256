import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { Category, EventDetails } from '../../shared/models/eventDetails';
import { CameraProfileService } from '../../shared/services/camera-profile.service';
import { Subject, catchError, finalize, of, takeUntil, tap } from 'rxjs';
import {
  EVENTFLAGERROR,
  EVENTREVIEWCOMMENTPOSTERROR,
  EVENTVIEWERROR,
  MULTIEVENTDATA,
  FLAGVALUE,
} from '../../shared/constants/camera-profile-constant';
import { EventReviewConstants } from '../../../app/shared/mocks/event-review-mockup';
import { LoginService } from '@agora/agora-ui-library';
import { EventDataView, EventView, FlagEventCount } from '../../../app/shared/models/camera-profile';

@Component({
  selector: 'app-event-review',
  templateUrl: './event-review.component.html',
  styleUrls: ['./event-review.component.scss'],
})
export class EventReviewComponent implements OnInit, OnDestroy {
  public eventForm: FormGroup = this.fb.group({
    eventId: [''],
    flag: ['', Validators.required],
    category: ['', Validators.required],
    comment: ['', Validators.required],
  });

  public categories: Category[];
  public controllerId: string;
  public gatewayId: string;
  public eventData: EventDetails;
  public userName: string;
  public userEmail: string;
  public comment = '';
  public isPostDisabled = true;
  public viewCount = 0;
  public getFlagCount: FlagEventCount;
  public acsUserId = 0;
  public flag: string;
  public selectedFlagButton?: string | null;
  public isExclude = true;
  public isLoading = true;
  public validCount = 0;
  public invalidCount = 0;
  public excludeCount = 0;
  private isCategorySelected = false;
  private destroyed = new Subject();

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    public dialogRef: MatDialogRef<EventReviewComponent>,
    private cameraProfileService: CameraProfileService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) private eventDetails: EventDetails[]
  ) {
    if (this.eventDetails.length === 1) {
      this.eventData = structuredClone(this.eventDetails[0]);
    } else {
      this.eventData = structuredClone(MULTIEVENTDATA);
    }
  }

  ngOnInit(): void {
    this.userName = this.loginService.getUserInfo()?.name ?? '';
    this.userEmail = this.loginService.getUserInfo()?.email ?? '';
    this.selectedFlagButton = null;
    this.isExclude = sessionStorage.getItem('isAdmin') === 'true' ? true : false;
    this.getTotalViewCount();
    this.getCategoryData();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  public getInitials(fullName: string): string {
    if (!fullName) {
      return '';
    }
    const nameParts = fullName.trim().split(' ');
    if (nameParts.length < 2) {
      return fullName.charAt(0).toUpperCase();
    }
    const firstNameInitial = nameParts[0].charAt(0).toUpperCase();
    const lastNameInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1].charAt(0).toUpperCase() : '';

    return `${firstNameInitial}${lastNameInitial}`;
  }

  public checkComment(): void {
    this.isPostDisabled = this.comment.trim().length === 0 ? true : false;
  }

  public postComment(): void {
    if (!this.isPostDisabled) {
      const commentJson = {
        eventId: this.eventData.eventId,
        comment: this.comment,
        username: this.userName,
        userEmail: this.userEmail,
      };
      this.cameraProfileService
        .saveEventComments(commentJson)
        .pipe(
          tap(() => {
            this.messageService.add({ severity: SlbSeverity.Success, summary: EventReviewConstants.successMessage });
          }),
          catchError(() => {
            this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTREVIEWCOMMENTPOSTERROR });

            return of<string>('');
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    }
    this.comment = '';
    this.isPostDisabled = true;
  }

  public getTotalViewCount(): void {
    this.cameraProfileService
      .getViewCounts(this.eventData.eventId)
      .pipe(
        tap((resData: EventView) => {
          this.viewCount = !resData ? 0 : resData.totalCount;
          const checkUser = resData?.views.find((user: { userEmail: string }) => user.userEmail === this.userEmail);
          if (!checkUser || !this.viewCount) {
            this.addViewCount();
          }
        }),
        catchError(() => {
          this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTVIEWERROR });

          return of<string>('');
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public addViewCount(): void {
    this.cameraProfileService
      .createViewCounts(this.eventData.eventId, this.userName, this.userEmail, this.acsUserId)
      .pipe(
        catchError(() => {
          this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTVIEWERROR });

          return of<string>('');
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public getCategoryData(): void {
    this.selectedFlagButton = null;
    this.cameraProfileService
      .getCategoryData(this.eventData.eventId)
      .pipe(
        tap((resData: FlagEventCount) => {
          this.getFlagCount = resData;
          this.validCount = !resData ? 0 : resData.totalCountValid;
          this.invalidCount = !resData ? 0 : resData.totalCountInvalid;
          this.excludeCount = !resData ? 0 : resData.totalCountExclude;
          const userDetail = this.getFlagCount.flags.find(item => item.userEmail === this.userEmail);
          this.selectedFlagButton = userDetail?.flag === 'NONE' ? null : userDetail?.flag.toLowerCase();
          this.isLoading = false;
        }),
        catchError(() => {
          this.isLoading = false;
          this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTFLAGERROR });

          return of<string>('');
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public setFlagData(inpFlag: string): void {
    if (inpFlag === FLAGVALUE.Valid) {
      this.flag = FLAGVALUE.Valid;
    } else if (inpFlag === FLAGVALUE.Invalid) {
      this.flag = FLAGVALUE.Invalid;
    } else {
      this.flag = FLAGVALUE.Exclude;
    }
    if (this.selectedFlagButton !== inpFlag && !this.isCategorySelected) {
      this.isCategorySelected = true;
      this.cameraProfileService
        .setFlagData(this.eventData.eventId, this.userName, this.userEmail, this.flag)
        .pipe(
          tap((resData: EventDataView) => {
            this.cameraProfileService.getEventData(false);
            this.getFlagCount = resData.eventDetails;
            this.validCount = !resData.eventDetails ? 0 : resData.eventDetails.totalCountValid;
            this.invalidCount = !resData.eventDetails ? 0 : resData.eventDetails.totalCountInvalid;
            this.excludeCount = !resData.eventDetails ? 0 : resData.eventDetails.totalCountExclude;
            this.messageService.add({ severity: SlbSeverity.Success, summary: resData.message });
            this.selectedFlagButton = inpFlag;
          }),
          catchError(() => {
            this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTFLAGERROR });

            return of<string>('');
          }),
          finalize(() => {
            this.isCategorySelected = false;
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    }
  }
}
