import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe, IMAGE_CONFIG, NgOptimizedImage } from '@angular/common';

import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCheckboxModule, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';

import { SlbNavigationFrameworkModule } from '@slb-dls/angular-material/navigation-framework';
import { SlbDatePickerModule } from '@slb-dls/angular-material/date-picker';
import { SlbSharedModule } from '@slb-dls/angular-material/shared';
import { SlbNotificationModule, MessageService } from '@slb-dls/angular-material/notification';
import { SlbNotificationsPanelModule } from '@slb-dls/angular-material/notifications-panel';
import { SlbLogoutModule } from '@slb-dls/angular-material/logout';
import { SlbPopoverModule } from '@slb-dls/angular-material/popover';
import { SlbButtonModule } from '@slb-dls/angular-material/button';
import { SlbBreadcrumbsModule } from '@slb-dls/angular-material/breadcrumbs';
import { SlbFormFieldModule } from '@slb-dls/angular-material/form-field';
import { SLB_THEMING_OPTIONS, SlbDateTimeFormats } from '@slb-dls/angular-material/core';
import { SlbMenuModule } from '@slb-dls/angular-material/menu';
import { SlbChartModule } from '@slb-dls/angular-material/chart';
import { SlbPaginationControlModule } from '@slb-dls/angular-material/pagination-control';
import { SlbDateTimePickerModule } from '@slb-dls/angular-material/date-time-picker';
import { SlbDropdownModule } from '@slb-dls/angular-material/dropdown';
import { SlbModalModule } from '@slb-dls/angular-material/modal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { themeConfig } from '../themes/theme.config';
import { SlbCarouselModule } from '@slb-dls/angular-material/carousel';
import { SlbTooltipModule } from '@slb-dls/angular-material/tooltip';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CameraComponent } from './camera/camera.component';

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { CameraProfileDashboardComponent } from './camera-profile/camera-profile-dashboard/camera-profile-dashboard.component';
import { EventReviewComponent } from './dashboard/event-review/event-review.component';
import { CustomCalendarComponent } from './camera-profile/custom-calendar/custom-calendar.component';
import { DATETIMEFORMATS } from './shared/constants/date-time-format';
import { DashboardService } from './shared/services/dashboard.service';
import { AlertsContainerComponent } from './camera-profile/alerts-container/alerts-container.component';
import { AlertsGridComponent } from './camera-profile/alerts-grid/alerts-grid.component';
import { AlertsTableComponent } from './camera-profile/alerts-table/alerts-table.component';

import { SharedModule } from './shared/shared.module';
import { CameraService } from './shared/services/camera.service';
import { AppConfiguration, AgoraUiLibraryModule, LoginService, AppConfigurationService } from '@agora/agora-ui-library';
import { Observable, take } from 'rxjs';
import { AuthModule, StsConfigLoader, StsConfigStaticLoader } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { TokenInterceptor } from './authentication/token.interceptor';
import { ThemeSwitcherComponent } from './theme-switcher/theme-switcher.component';
import { SlbRadioButtonGroupModule } from '@slb-dls/angular-material/radio-button-group';
import { TimeZoneService } from './shared/services/time-zone.service';
import { HighchartsChartModule } from 'highcharts-angular';
import { CameraProfileHeaderComponent } from './camera-profile/camera-profile-header/camera-profile-header.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { UserSettingComponent } from './global-settings/user-setting/user-setting.component';
import { UnitsComponent } from './global-settings/units/units.component';
import { GlobalViewComponent } from './global-view/global-view.component';
import { GlobalEventComponent } from './global-view/global-event/global-event.component';
import { GlobalEventGridComponent } from './global-view/global-event-grid/global-event-grid.component';
import { GlobalChartsComponent } from './global-view/global-charts/global-charts.component';
import { SlbDatePickerRangeModule } from '@slb-dls/angular-material/date-range-picker';
import { GlobalTableComponent } from './global-view/global-table/global-table.component';
import { GlobalViewService } from './shared/services/global-view.service';
import { EventPopoverComponent } from './event-popover/event-popover.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  subscriptSizing: 'fixed',
};

const defaultColor = {
  color: 'primary',
};

const CUSTOM_FORMATS: SlbDateTimeFormats = DATETIMEFORMATS;

export const initializeAppConfiguration = (configService: AppConfigurationService): (() => Observable<AppConfiguration | null>) => {
  configService.getApplicationConfigurations();

  return () => configService.applicationConfigurations$.pipe(take(1));
};

const authFactory = (configService: AppConfigurationService): StsConfigStaticLoader => {
  const config = configService.applicationConfigurationsValue;

  return new StsConfigStaticLoader(config);
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CameraComponent,
    CameraProfileDashboardComponent,
    AlertsContainerComponent,
    AlertsGridComponent,
    AlertsTableComponent,
    EventReviewComponent,
    CustomCalendarComponent,
    ThemeSwitcherComponent,
    CameraProfileHeaderComponent,
    UnauthorizedComponent,
    UserSettingComponent,
    UnitsComponent,
    GlobalViewComponent,
    GlobalEventComponent,
    GlobalEventGridComponent,
    GlobalChartsComponent,
    GlobalTableComponent,
    EventPopoverComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AgoraUiLibraryModule.forRoot({
      configurationApiUrl: environment.appConfigApiUrl,
      isAutomationTesting: environment.isAutomationTesting,
      identityProviderClientKey: environment.identityProviderClientKey,
      identityProviderClientSecret: environment.identityProviderClientSecret,
      identityProviderRedirectUrl: environment.identityProviderRedirectUrl,
      identityProviderUrl: environment.identityProviderUrl,
    }),
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authFactory,
        deps: [AppConfigurationService],
      },
    }),
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatCardModule,
    SlbSharedModule,
    SlbButtonModule,
    SlbFormFieldModule,
    SlbPopoverModule,
    SlbNotificationModule,
    SlbNotificationsPanelModule,
    SlbNavigationFrameworkModule,
    SlbBreadcrumbsModule,
    SlbLogoutModule,
    SlbMenuModule,
    SlbDropdownModule,
    SlbChartModule,
    SlbPaginationControlModule,
    SlbModalModule,
    MatSelectModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatTableModule,
    SlbModalModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    SlbDateTimePickerModule,
    SlbRadioButtonGroupModule,
    SharedModule,
    SlbCarouselModule,
    SlbTooltipModule,
    HighchartsChartModule,
    NgOptimizedImage,
    SlbDatePickerModule,
    SlbDatePickerRangeModule,
  ],
  providers: [
    { provide: SLB_THEMING_OPTIONS, useValue: themeConfig },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: defaultColor },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: defaultColor },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MessageService, useClass: MessageService },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS },
    { provide: DateAdapter, useClass: NativeDateAdapter },
    TimeZoneService,
    DashboardService,
    GlobalViewService,
    CameraService,
    AppConfigurationService,
    LoginService,
    DatePipe,
    { provide: APP_INITIALIZER, useFactory: initializeAppConfiguration, deps: [AppConfigurationService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        breakpoints: [16, 48, 96, 128, 384, 640, 750, 828, 1080, 1200, 1920],
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
