import { Injectable } from '@angular/core';
import { DashabordChartSummary } from '../models/dashboardChartSummary';
import { Observable } from 'rxjs';
import { AssetHierarchy } from '../models/assetHierarchy';
import { DashboardAlertData } from '../models/dashabordAlertData';
import { HttpClient } from '@angular/common/http';
import { ApiConstants } from '../constants/api-constant';
import { environment } from '../../../environments/environment';
import { AlertFilter } from '../models/alertFilter';
import { EventDetails } from '../models/eventDetails';
import { CameraConstants } from '../constants/camera-constant';
import { EventChart } from '../models/eventChart';
import { countryList } from '../models/dashabordEventDetails';
import { DashboardConstants } from '../constants/dashboard-constants';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  public getDashabordSummaryData(
    country: string,
    startTime: string,
    endTime: string,
    showAllEvents: string
  ): Observable<DashabordChartSummary> {
    return this.http.get<DashabordChartSummary>(
      `${environment.apiBaseUrl}${ApiConstants.BASEURL}${ApiConstants.DASHBOARD}get-summary-data?countryName=${country}
&startDate=${startTime}&endDate=${endTime}&showAllEvents=${showAllEvents}`
    );
  }

  // eslint-disable-next-line no-unused-vars
  public getAssetHirarchy(country: string): Observable<AssetHierarchy> {
    return this.http.get<AssetHierarchy>(
      `${environment.apiBaseUrl}${ApiConstants.BASEURL}${ApiConstants.DASHBOARD}get-asset-hierarchy?countryName=${country}`
    );
  }

  // eslint-disable-next-line no-unused-vars
  public getAlertData(countryName: string, alertFilter: AlertFilter, showAllEvents: string): Observable<DashboardAlertData> {
    return this.http.get<DashboardAlertData>(
      `${environment.apiBaseUrl}${ApiConstants.BASEURL}${ApiConstants.DASHBOARD}get-alert-data?countryName=${countryName}
&rigId=${alertFilter.rigId}&workflow=${alertFilter.workflowName}&gatewayStatus=${alertFilter.gatewayStatus}
&cameraStatus=${alertFilter.cameraStatus}&showAllEvents=${showAllEvents}
&pageNo=${alertFilter.pageNumber}&records=${alertFilter.recordsPerPage}`
    );
  }

  public getDashabordEventTrendingData(
    country: string,
    startTime: string,
    endTime: string,
    timezone: string,
    showAllEvents: string
  ): Observable<EventChart> {
    return this.http.get<EventChart>(
      `${environment.apiBaseUrl}${ApiConstants.BASEURL}${ApiConstants.EVENT}get-event-trend?gatewayId=&startDate=${startTime}
&endDate=${endTime}&countryName=${country}&controllerId=&timeZone=${timezone}&showAllEvents=${showAllEvents}`
    );
  }

  public getCountryData(): Observable<countryList> {
    return this.http.get<countryList>(`${environment.apiBaseUrl}${ApiConstants.BASEURL}${ApiConstants.DASHBOARD}get-country-list`);
  }

  public getRigState(value: number): string {
    switch (value) {
      case 0:
        return 'Wits';
      case 1:
        return 'Gn5';
      default:
        return 'NA';
    }
  }

  public getRigEventDetails(event: EventDetails): string {
    let eventInfo = '';
    if (event.peopleInsideRedZone > 0) {
      if (event?.rigState && event.rigState !== 'NA') {
        eventInfo = this.formatLabel(event.rigState);
      } else {
        eventInfo = CameraConstants.NoRigData;
      }
    } else if (event.ppeViolations > 0) {
      /** For PPE the rigstate value will comes in comment */
      if (event.comment) {
        const comment = event.comment.toString().replace(/[&\/\\#+()$~%.'":*?<>{}]/g, '');
        const missingEvents = comment.split(',');
        missingEvents.sort((a, b) => (a.replace(/\s/g, '') > b.replace(/\s/g, '') ? 1 : -1));
        if (missingEvents.length > 0) {
          missingEvents.map((e: string, index: number) => {
            const missingPPE = this.getMissingPpe(e.replace(/\s/g, ''));
            if (missingPPE !== '') {
              eventInfo = eventInfo + missingPPE;
              if (index < missingEvents.length - 1) {
                eventInfo = eventInfo + ', ';
              }
            }
          });
        } else {
          eventInfo = this.getMissingPpe(event.comment.replace(/\s/g, ''));
        }
      } else {
        eventInfo = CameraConstants.NoRigData;
      }
    } else if (event.catWalkViolations > 0) {
      if (event?.rigState) {
        eventInfo = this.formatLabel(event.rigState);
      } else {
        eventInfo = CameraConstants.NoRigData;
      }
    } else {
      eventInfo = CameraConstants.NoRigData;
    }

    return eventInfo;
  }

  public downloadZipFile(url: string, controllerId: string): void {
    const blob = new Blob([url], {
      type: 'application/zip',
    });
    const bloburl = window.URL.createObjectURL(blob);
    const filename = 'SLB Edge-Vision Intelligence-' + controllerId;
    const dwldLink = document.createElement('a');

    dwldLink.setAttribute('href', bloburl);
    dwldLink.setAttribute('download', filename + '.zip');
    dwldLink.setAttribute('target', '_blank');
    dwldLink.click();
    dwldLink.remove();
  }

  public unsubscribeEmail(user_email: string, org_id: number): Observable<string> {
    return this.http.get<string>(
      `${environment.apiBaseUrl}${ApiConstants.BASEURL}${ApiConstants.DASHBOARD}unsusbscribe-email/${user_email}/${org_id}`
    );
  }

  public setLabel(eventDetails: EventDetails): string {
    let label = '';
    if (eventDetails?.peopleInsideRedZone > 0) {
      label = DashboardConstants.REDZONE_LABEL;
    } else if (eventDetails?.ppeViolations > 0) {
      label = DashboardConstants.PPE_LABEL;
    } else if (eventDetails?.catWalkViolations > 0) {
      label = DashboardConstants.CATWALK_LABEL;
    }

    return label;
  }

  public setLabelEventReview(eventDetails: EventDetails): string {
    let label = '';
    if (eventDetails?.peopleInsideRedZone > 0) {
      label = DashboardConstants.REDZONE_EVENT_REVIEW_LABEL;
    } else if (eventDetails?.ppeViolations > 0) {
      label = DashboardConstants.PPE_EVENT_REVIEW_LABEL;
    } else if (eventDetails?.catWalkViolations > 0) {
      label = DashboardConstants.CATWALK_EVENT_REVIEW_LABEL;
    }

    return label;
  }

  public getIcon(eventDetails: EventDetails): string {
    let icon = '';
    if (eventDetails?.peopleInsideRedZone > 0) {
      icon = DashboardConstants.REDZONE_ICON;
    } else if (eventDetails?.ppeViolations > 0) {
      icon = DashboardConstants.PPE_ICON;
    } else if (eventDetails?.catWalkViolations > 0) {
      icon = DashboardConstants.CATWALK_ICON;
    }

    return icon;
  }

  private formatLabel(key: string): string {
    if (key === DashboardConstants.OFF) {
      return key;
    } else {
      key.replace(key[0], key[0].toUpperCase());
      const splitBasedonUpperCase = key.replace(key[0], key[0].toUpperCase()).match(/[A-Z][a-z]+/g);
      if (splitBasedonUpperCase?.length) {
        return splitBasedonUpperCase.join(' ');
      }

      return '';
    }
  }

  private getMissingPpe(value: string): string {
    switch (value?.toUpperCase()) {
      case 'HELMET':
        return 'Helmet';
      case 'COVERALL':
        return 'Coverall';
      case 'GLOVE':
        return 'Glove';
      case 'VEST':
        return 'High Visibility Vest';
      default:
        return '';
    }
  }
}
