export const environment = {
  production: true,
  apiBaseUrl: 'https://evp-hse.agoraiot.app',
  isAutomationTesting: false,
  appConfigApiUrl: '',
  identityProviderUrl:'https://csi.slb.com/v2',
  identityProviderClientKey: '18312bf2352999e93dc3008b530de083',
  identityProviderClientSecret:'8d833361c3b648bc8ec8228dce8c44b5ded3648b22b',
  identityProviderRedirectUrl: 'https://evp-hse.agoraiot.app/login/logincallback'
};
