<div class="event-review-title">
  <div class="modal-close-icon">
    <slb-modal-close-button id="event_review_close_btn" buttonType="Icon" [dialogRef]="dialogRef"></slb-modal-close-button>
  </div>
  <div class="view-container" *ngIf="!isLoading; else loader">
    <div class="view">
      <mat-icon svgIcon="preview" class="view-icon"></mat-icon>
      <span class="view-label">{{ viewCount }} Views</span>
    </div>
    <div class="view-button">
      <button
        slb-secondary-button
        [ngClass]="{ 'flag-blue': selectedFlagButton !== 'valid', 'flag-green': selectedFlagButton === 'valid' }"
        id="agora_vi_event_review_save_btn"
        class="valid-button"
        (click)="setFlagData('valid')">
        <mat-icon svgIcon="thumbs-up" class="view-icon"></mat-icon>
        <span class="view-label">Valid ({{ validCount }})</span>
      </button>
    </div>
    <div class="view-button">
      <button
        slb-secondary-button
        [ngClass]="{ 'flag-blue': selectedFlagButton !== 'invalid', 'flag-green': selectedFlagButton === 'invalid' }"
        id="agora_vi_event_review_save_btn"
        class="valid-button"
        (click)="setFlagData('invalid')">
        <mat-icon svgIcon="thumbs-down" class="view-icon"></mat-icon>
        <span class="view-label">Invalid ({{ invalidCount }}) </span>
      </button>
    </div>
    <div class="view-button" *ngIf="isExclude">
      <button
        slb-secondary-button
        [ngClass]="{ 'flag-blue': selectedFlagButton !== 'exclude', 'flag-green': selectedFlagButton === 'exclude' }"
        id="agora_vi_event_review_save_btn"
        class="valid-button"
        (click)="setFlagData('exclude')">
        <mat-icon svgIcon="remove" class="view-icon"></mat-icon>
        <span class="view-label">Exclude ({{ excludeCount }})</span>
      </button>
    </div>
  </div>
  <div class="bottom-container" *ngIf="!isLoading">
    <div class="remark-container">
      <div class="remark-label">
        <div class="user-container">
          <div class="user-icon">{{ getInitials(userName) }}</div>
          <span class="user-label">{{ userName }}</span>
        </div>
      </div>
      <div class="comments">
        <mat-form-field slbFormField class="text-area-field">
          <textarea
            matInput
            class="comment"
            cdkFocusInitial
            type="textarea"
            [(ngModel)]="comment"
            (input)="checkComment()"
            id="agora_vi_event_review_comment_textarea"
            maxlength="2000"
            rows="4"
            placeholder="Write your thoughts"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="actions">
      <button slb-secondary-button id="agora_vi_event_review_save_btn" [disabled]="isPostDisabled" (click)="postComment()" class="save">
        Post
      </button>
    </div>
  </div>
  <ng-template #loader>
    <div class="spinner">
      <mat-spinner *ngIf="isLoading" [diameter]="40"></mat-spinner>
    </div>
  </ng-template>
</div>
