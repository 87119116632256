<div class="units-container">
  <h2>Unit settings</h2>
  <p class="unit-subhead">Select unit system column as per your requirement.</p>
  <p class="time-stamp" *ngIf="timeStamp">Last time unit updated: {{ timeStamp | date : 'mediumDate' }}</p>
  <table
    mat-table
    *ngIf="dataSource && dataSource.data && dataSource.data.length > 0 && !loader"
    matSort
    class="table"
    [dataSource]="dataSource"
    matSortActive="timestamp"
    matSortDirection="desc">
    <ng-container matColumnDef="unitName">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="unit-name" *matCellDef="let element; let i = index" id="{{ 'vi_unit_head' + (i + 1) }}">
        {{ element.unitName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="metric">
      <th
        mat-header-cell
        class="table-header"
        *matHeaderCellDef
        [ngClass]="{ 'highlight-header': selectedColumnName === 'metric' }"
        (click)="highlight('metric', true)">
        Metric
        <mat-icon svgIcon="check" *ngIf="selectedColumnName === 'metric'" class="checked-icon"></mat-icon>
      </th>
      <td
        [ngClass]="{ highlight: selectedColumnName === 'metric' }"
        mat-cell
        *matCellDef="let element; let i = index"
        id="{{ 'vi_unit_metric' + (i + 1) }}">
        {{ element.metric }}
      </td>
    </ng-container>
    <ng-container matColumnDef="imperial">
      <th
        mat-header-cell
        class="table-header"
        [ngClass]="{ 'highlight-header': selectedColumnName === 'imperial' }"
        *matHeaderCellDef
        (click)="highlight('imperial', true)">
        Imperial
        <mat-icon svgIcon="check" *ngIf="selectedColumnName === 'imperial'" class="checked-icon"></mat-icon>
      </th>
      <td
        mat-cell
        [ngClass]="{ highlight: selectedColumnName === 'imperial' }"
        *matCellDef="let element; let i = index"
        id="{{ 'vi_unit_imperial' + (i + 1) }}">
        {{ element.imperial }}
      </td>
    </ng-container>
    <ng-container matColumnDef="custom">
      <th
        mat-header-cell
        class="table-header"
        [ngClass]="{ 'highlight-header': selectedColumnName === 'custom' }"
        *matHeaderCellDef
        (click)="highlight('custom', true)">
        Custom
        <mat-icon svgIcon="check" *ngIf="selectedColumnName === 'custom'" class="checked-icon"></mat-icon>
      </th>
      <td
        mat-cell
        class="custom"
        *matCellDef="let element; let i = index"
        [ngClass]="{ highlight: selectedColumnName === 'custom' }"
        id="{{ 'vi_unit' + '_head' + (i + 1) }}">
        <div [formGroup]="customFormGroup">
          <mat-select
            class="custom-select"
            (selectionChange)="checkCustomValid()"
            [formControlName]="element.formName"
            placeholder="Select unit">
            <mat-option *ngFor="let unit of element.custom" [value]="unit">{{ unit }}</mat-option>
          </mat-select>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="table-content" *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="spinner" *ngIf="loader">
    <app-spinner></app-spinner>
  </div>
  <div class="footer">
    <p>
      <span *ngIf="selectedColumnName === 'custom'">Customize units as per your choice & save</span>
      <button type="submit" class="save-btn" slb-button [disabled]="disableSave" (click)="saveUnits()">Save</button>
    </p>
  </div>
</div>
<slb-toast> </slb-toast>
