import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, catchError, filter, of, switchMap, takeUntil, tap } from 'rxjs';
import { TimeZone } from '../../shared/models/dashabordAlertData';
import { Camera, Gateway } from '../../shared/models/gateway';
import { EventDetails } from '../../shared/models/eventDetails';
import { TIMEZONE } from '../../shared/constants/timezone-constant';
import { DashboardConstants } from '../../shared/constants/dashboard-constants';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { AlertFilter } from '../../shared/models/alertFilter';
import { CameraProfileService } from '../../shared/services/camera-profile.service';
import { TimeZoneService } from '../../shared/services/time-zone.service';
import * as Highcharts from 'highcharts';
import noData from 'highcharts/modules/no-data-to-display';
import { GLOBALLINECHARTOPTIONS } from '../../shared/constants/chart-constant';
import {
  DONUT_SERIES,
  EVENTCOLORS,
  GLOBAL_EVENT_STATUS_CHART_OPTION,
  GLOBALHIGHCHARTCHARTDATA,
} from '../../shared/constants/camera-profile-constant';
import { CameraProfileDetails } from '../../shared/models/cameraDetails';
import { GlobalViewService } from '../../shared/services/global-view.service';
import { CHARTTIMELINE, DAYMODE } from '../../shared/constants/global-view-constant';
import { EventStatusItems, GlobalChartSummary } from '../../shared/models/global-view';
import { Event } from '../../shared/models/eventChart';

noData(Highcharts);
@Component({
  selector: 'app-global-charts',
  templateUrl: './global-charts.component.html',
  styleUrls: ['./global-charts.component.scss'],
})
export class GlobalChartsComponent implements OnInit, OnDestroy {
  public dateRanges = DashboardConstants.dateRanges;
  public popOverCameraDetails: EventDetails | null;
  public workflowList: string[] = [];
  public timezoneList = TIMEZONE;
  public currentZone = '';
  public currentZoneArea = '';
  public currentZoneDetails!: TimeZone;
  public timeZoneChange = false;
  public totalVoilation = 0;
  public summaryLoader = false;
  public alertsLoader = false;
  public riskLoader = false;
  public alertFilter: AlertFilter = JSON.parse(JSON.stringify(DashboardConstants.defaultFilter));
  public expandedIndex = 0;
  public hasPrevious = false;
  public hasNext = false;
  public selectedPopOverIndex = -1;
  public Highcharts = Highcharts;
  public updateRiskChart: boolean;
  public eventOptions: Highcharts.Options;
  public eventChartOptions: Highcharts.Options;
  public updateChart: boolean;
  public updateCameraChart: boolean;
  public eventType = 'none';
  public totalEvents = 0;
  public eventLineChartOption: Highcharts.Options;
  public updateEventLineChart: boolean;
  public cameraProfileData: CameraProfileDetails;
  public chartTimeLine = '';
  public startDate: Date;
  public endDate: Date;
  public riskData: EventStatusItems[] = [];
  public filterData: { [key: string]: string[] };
  private showAllEvents: string;
  private destroyed = new Subject();
  private currentRig: Gateway;
  private timeZone: string;
  private summaryState = new BehaviorSubject(true);
  private summaryState$ = this.summaryState.asObservable();

  constructor(
    private messageService: MessageService,
    private cameraProfileService: CameraProfileService,
    private timeZoneService: TimeZoneService,
    private globalViewService: GlobalViewService
  ) {
    this.timeZoneService.timeZoneDetails$
      .pipe(
        filter((zone: TimeZone) => !!Object.keys(zone).length),
        tap((timeZoneDetails: TimeZone) => {
          this.currentZone = timeZoneDetails.value;
          this.currentZoneArea = timeZoneDetails?.label;
          this.currentZoneDetails = timeZoneDetails;
        })
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.summaryLoader = true;
    this.alertsLoader = true;
    this.riskLoader = true;
    this.updateChart = false;
    this.updateEventLineChart = false;
    const expandedIndex = sessionStorage.getItem('rigAccordionIndex');
    this.expandedIndex = isNaN(parseInt(expandedIndex ?? '0', 10)) ? 0 : parseInt(expandedIndex ?? '0', 10);
    this.timeZone = sessionStorage.getItem('timezone') ?? '';
    this.eventChartOptions = JSON.parse(JSON.stringify(GLOBAL_EVENT_STATUS_CHART_OPTION));
    this.eventLineChartOption = JSON.parse(JSON.stringify(GLOBALLINECHARTOPTIONS));
  }

  public onImageClick(camera: Camera, index: number, rig: Gateway): void {
    if (camera?.eventDetails) {
      camera.eventDetails.rigName = rig.rigName;
      camera.eventDetails.gatewayId = rig.gatewayId;
      camera.eventDetails.controllerId = camera.controllerId;
      this.eventType = 'none';
      this.selectedPopOverIndex = index;
      this.currentRig = rig;
      this.checkHasNext(index + 1, true);
      this.checkHasPrevious(index - 1, true);
      const data: EventDetails = camera?.eventDetails ?? ({} as EventDetails);
      data.isVideo = true;
      this.updateLatestPopoverDetails(camera);
    } else {
      this.showNoEventToasterMesaage(camera);
    }
  }

  public onEventClick(event: number, isClick: boolean): void {
    if (event > this.selectedPopOverIndex) {
      this.eventType = 'next';
    } else if (event < this.selectedPopOverIndex) {
      this.eventType = 'prev';
    }
    this.selectedPopOverIndex = event;
    this.checkHasNext(event, isClick);
    this.checkHasPrevious(event, isClick);
    if (this.currentRig.cameras[this.selectedPopOverIndex]) {
      const camera: Camera = this.currentRig.cameras[this.selectedPopOverIndex];
      if (camera.eventDetails) {
        this.updateLatestPopoverDetails(camera);
      }
    }
  }

  public updateLatestPopoverDetails(camera: Camera): void {
    const data: EventDetails = camera.eventDetails ?? ({} as EventDetails);
    data.isVideo = true;
    data.cameraStatus = camera.cameraStatus;
    data.cameraName = camera.name;
    data.ppeViolations = camera.ppeViolations;
    data.peopleInsideRedZone = camera?.eventDetails?.peopleInsideRedZone ?? 0;
    data.rigState = camera.eventDetails?.rigState ?? 'NA';
    this.popOverCameraDetails = data;
  }

  public setViolationChartData(data: { [keys: string]: number }): void {
    if (data) {
      this.eventChartOptions.series = [];
      let options: Highcharts.PointOptionsObject[] = [];
      options = this.createDonutChartDataOption(data);
      const series: Highcharts.SeriesPieOptions = JSON.parse(JSON.stringify(DONUT_SERIES));
      series.data = options;
      this.eventChartOptions.series?.push(series);
      this.totalVoilation = data.total;
    }
    this.updateChart = true;
    this.alertsLoader = false;
  }

  public onTimezoneChange(): void {
    this.timeZoneService.setSelectedTimeZone(this.currentZoneDetails);
    this.timeZoneChange = true;
    this.triggerData();
    this.cameraProfileService.getEventData(false);
  }

  public showNoEventToasterMesaage(camera: Camera): void {
    this.messageService.add({ severity: SlbSeverity.Info, summary: `${DashboardConstants.NOCAMERAEVENT} ${camera.name}` });
  }

  public setChartTimeline(dayMode: string | null, startDate?: Date, endDate?: Date): void {
    switch (dayMode) {
      case DAYMODE.HOUR:
        this.chartTimeLine = CHARTTIMELINE.HOUR;
        break;
      case DAYMODE.DAY:
        this.chartTimeLine = CHARTTIMELINE.DAY;
        break;
      case DAYMODE.TWODAY:
        this.chartTimeLine = CHARTTIMELINE.TWODAY;
        break;
      case DAYMODE.WEEK:
        this.chartTimeLine = CHARTTIMELINE.WEEK;
        break;
      case DAYMODE.MONTH:
        this.chartTimeLine = CHARTTIMELINE.MONTH;
        break;
      case DAYMODE.YEAR:
        this.chartTimeLine = CHARTTIMELINE.YEAR;
        break;
      case DAYMODE.CUSTOM:
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        this.chartTimeLine = formatter.format(startDate) + ' - ' + formatter.format(endDate);
        break;
      default:
        this.chartTimeLine = '';
    }
  }

  public switchDays(startDate: Date, endDate: Date): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.triggerData();
  }

  public workFlowEventStatusChange(filteredData: { [keys: string]: string[] }): void {
    this.filterData = filteredData;
    this.triggerData();
  }

  public setFirstInitialization(startDate: Date, endDate: Date, filteredData: { [keys: string]: string[] }): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.filterData = filteredData;
    this.getSummaryData();
  }

  public getSummaryData(): void {
    this.summaryState$
      .pipe(
        switchMap(() =>
          this.cameraProfileService.eventData$.pipe(
            tap(() => {
              this.summaryLoader = true;
              this.alertsLoader = true;
              this.showAllEvents = sessionStorage.getItem('showAllEvents') || '';
              this.eventOptions = JSON.parse(JSON.stringify(GLOBALHIGHCHARTCHARTDATA));
              this.timeZone = sessionStorage.getItem('timezone') ?? '';
            }, takeUntil(this.destroyed)),
            switchMap(() =>
              this.globalViewService
                .getEventChart(this.startDate.toISOString(), this.endDate.toISOString(), this.timeZone, this.filterData, this.showAllEvents)
                .pipe(
                  catchError(() => {
                    this.messageService.add({
                      severity: SlbSeverity.Error,
                      summary: DashboardConstants.dashabordChartAPIError,
                      closable: true,
                      sticky: true,
                    });
                    this.summaryLoader = false;
                    this.alertsLoader = false;

                    return of<GlobalChartSummary>({} as GlobalChartSummary);
                  }),
                  tap((data: GlobalChartSummary) => {
                    if (data && data.eventStatus && data.eventTrend && data.eventAtRisk) {
                      const expandedIndex = sessionStorage.getItem('rigAccordionIndex') ?? '0';
                      this.expandedIndex = parseInt(expandedIndex ?? '0', 10);
                      this.setViolationChartData(data?.eventStatus);
                      this.setEventLineChartData(data?.eventTrend);
                      this.createEventChart(data?.eventAtRisk);
                    } else {
                      this.totalVoilation = 0;
                      this.totalEvents = 0;
                      this.eventOptions.series = [];
                      this.summaryLoader = false;
                      this.riskLoader = false;
                      this.alertsLoader = false;
                    }
                  }),
                  takeUntil(this.destroyed)
                )
            ),
            takeUntil(this.destroyed)
          )
        )
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  private triggerData(): void {
    if (this.filterData) {
      const isFilter = Object.keys(this.filterData).filter(data => this.filterData[data]?.length);
      if (!isFilter?.length) {
        this.totalVoilation = 0;
        this.totalEvents = 0;
        this.eventOptions.series = [];
      }
    }
  }

  private setEventLineChartData(chartData: Event[]): void {
    const eventSeries: Highcharts.SeriesOptionsType[] = [];
    let totalEvents = 0;
    DashboardConstants.EVENTTYPE.map(type => {
      const seriesData: Highcharts.PointOptionsObject[] = [];
      chartData?.map(e => {
        const eventByType = e.eventTotal.find((ev: { eventType: string }) => this.checkType(type, ev.eventType));
        if (eventByType) {
          seriesData.push({
            y: eventByType.total,
            x: new Date(e.date + ' 00:00').getTime(),
          } as Highcharts.PointOptionsObject);
          totalEvents += eventByType.total;
        }
      });
      if (seriesData.length > 0) {
        eventSeries.push({
          lineWidth: 2,
          type: 'line',
          name: type,
          color: EVENTCOLORS.filter(tag => this.checkType(type, tag.name))[0]
            ? EVENTCOLORS.filter(tag => this.checkType(type, tag.name))[0].color
            : '',
          data: seriesData,
          visible: true,
        } as Highcharts.SeriesOptionsType);
      }
    });
    this.eventLineChartOption.series = eventSeries;
    this.totalEvents = totalEvents;
    this.updateEventLineChart = true;
    this.summaryLoader = false;
  }

  private checkHasNext(index: number, isClick: boolean): void {
    const currentIndex = isClick ? index : this.selectedPopOverIndex;
    const hasNext = currentIndex >= this.currentRig.cameras.length ? false : true;
    if (hasNext) {
      for (let i = currentIndex; i < this.currentRig.cameras.length; i++) {
        this.hasNext = i >= this.currentRig.cameras.length ? false : true;
        if (this.currentRig.cameras[i]?.eventDetails) {
          this.hasNext = i >= this.currentRig.cameras.length - 1 ? false : true;
          if (!isClick && this.eventType === 'next') {
            this.selectedPopOverIndex = i;
          } else {
            this.hasNext = true;
          }
          break;
        } else {
          this.hasNext = false;
        }
      }
    } else {
      if (isClick) {
        this.hasNext = currentIndex >= this.currentRig.cameras.length ? false : true;
      } else {
        this.hasNext = false;
      }
    }
  }

  private checkHasPrevious(index: number, isClick: boolean): void {
    const currentIndex = isClick ? index : this.selectedPopOverIndex;
    const hasPrevious = currentIndex <= 0 ? false : true;
    if (hasPrevious) {
      for (let i = currentIndex; i >= 0; i--) {
        if (i >= 0) {
          if (this.currentRig.cameras[i]?.eventDetails) {
            this.hasPrevious = i <= 0 ? false : true;
            if (!isClick && this.eventType === 'prev') {
              this.selectedPopOverIndex = i;
            } else {
              if (isClick) {
                this.hasPrevious = i < 0 ? false : true;
              }
            }
            break;
          } else {
            this.hasPrevious = false;
          }
        }
      }
    } else {
      if (isClick) {
        this.hasPrevious = currentIndex < 0 ? false : true;
      } else {
        this.hasPrevious = false;
      }
    }
  }

  private checkType(type: string, eventType: string): boolean {
    return type.split(' ').join('').toLowerCase().includes(eventType.toLowerCase());
  }

  private createEventChart(chartData: { [keys: string]: number }): void {
    this.eventOptions.series = [];
    let options = chartData.total ? this.createDonutChartDataOption(chartData) : [];
    options = options.sort((a, b) => (a.y && b.y ? b.y - a.y : 0));
    if (options.length > 0) {
      this.eventOptions.series?.push({
        type: 'pie',
        innerSize: '50%',
        data: options,
        showInLegend: true,
        dataLabels: {
          enabled: true,
          distance: 10,
          format: '<b>{point.y}</b><br>{point.count}',
        },
      } as Highcharts.SeriesPieOptions);
    }
    this.riskData.sort((a, b) => a.name.localeCompare(b.name));
    this.updateRiskChart = true;
    this.riskLoader = false;
  }

  private createDonutChartDataOption(chartData: { [keys: string]: number }): Highcharts.PointOptionsObject[] {
    const options: Highcharts.PointOptionsObject[] = [];
    Object.keys(chartData).map((key: string) => {
      const label = this.formatLabel(key);
      if (key !== 'total' && chartData[key] !== 0 && chartData[key] !== null) {
        const color = EVENTCOLORS.filter(tag => tag.name === label)[0] ? EVENTCOLORS.filter(tag => tag.name === label)[0].color : '';
        options.push({
          name: label,
          y: chartData[key],
          color,
          fillColor: color,
        });
      }
    });

    return options;
  }

  private formatLabel(key: string): string {
    key.replace(key[0], key[0].toUpperCase());
    const splitBasedonUpperCase = key.replace(key[0], key[0].toUpperCase()).match(/[A-Z][a-z]+/g);
    if (splitBasedonUpperCase?.length) {
      return splitBasedonUpperCase.join(' ');
    }

    return '';
  }
}
