import { CommentDetail } from '../models/eventDetails';

export class RIGDETAILS {
  public static RIGDATA_NONBSP: CommentDetail[] = [
    {
      upperBound: 'BlockPositionUpperBound_m',
      lowerBound: 'BlockPositionLowerBound_m',
      currentValue: 'BPOS_m',
      unit: 'Length/depth',
      defaultUnit: '(m)',
      range: 'Inside',
      label: 'BPOS',
    },
    {
      upperBound: 'BlockVelocityUpperBound_mps',
      lowerBound: 'BlockVelocityLowerBound_mps',
      currentValue: 'BVEL_mps',
      unit: 'Speed/Velocity',
      range: 'Outside',
      defaultUnit: '(m/s)',
      label: 'BVEL',
    },
    {
      upperBound: 'RPMThreshold_rpm',
      lowerBound: '',
      currentValue: 'RPM',
      unit: 'Angular speed/velocity',
      range: 'Outside',
      defaultUnit: '(rpm)',
      label: 'RPM',
    },
    {
      upperBound: 'StandpipePressureThreshold_Pa',
      lowerBound: '',
      currentValue: 'SPPA_Pa',
      unit: 'Pressure',
      range: 'Outside',
      defaultUnit: '(Pa)',
      label: 'SPPA',
    },
  ];
}
export const BSP = 'bsp';
export const RPM = 'RPM';
export const SPPA = 'SPPA';
export const DEFAULT_VALUE = 0;
export const INSIDE = 'Inside';
export const OUTSIDE = 'Outside';
export const RED = 'red';
export const GREEN = 'green';
export const RIGSTATE = {
  BLOCKMOVEMENT: 'Block Movement',
  BLOCKROTATION: 'Block Rotation',
  HIGHPRESSURE: 'High Pressure',
  BLOCKMOVINGUP: 'Block Moving Up',
  BLOCKMOVINGDOWN: 'Block Moving Down',
  MUDCIRCULATION: 'Mud Circulation',
  DRILLINGROTATION: 'Drilling Rotation',
};
export const RIGZONE = {
  'Block Movement': ['BVEL', 'BPOS'],
  'Block Rotation': ['BPOS', 'RPM'],
  'High Pressure': ['BPOS', 'SPPA'],
  'Block Moving Up': ['BVEL', 'Hookload'],
  'Block Moving Down': ['BVEL', 'BPOS', 'Hookload'],
  'Mud Circulation': ['Flow', 'Hookload'],
  'Drilling Rotation': ['Flow', 'RPM', 'SPPA', 'Bit Depth'],
};
