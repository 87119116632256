<div *ngIf="!isLoading; else loader" class="wrappar">
  <div class="chartLoader" *ngIf="reviewLoader">
    <ng-container *ngTemplateOutlet="loader"></ng-container>
  </div>
  <div id="vi_camer_profile_dashboard" *ngIf="!reviewLoader">
    <mat-card class="no-link risk-events">
      <mat-card-header class="risk-events-header" id="vi_total_gateway_chart_dashboard_header">
        <mat-card-title>Total events: {{ noOfEvents }}</mat-card-title>
        <mat-card-subtitle class="sub-label">Last 7 days</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content id="vi_camer_profile_details" class="risk-chart">
        <highcharts-chart
          class="event-line-chart"
          *ngIf="eventLineOptions.series && eventLineOptions.series.length > 0; else no_profile_data"
          [Highcharts]="Highcharts"
          [options]="eventLineOptions"
          [(update)]="updateEventLineChart"></highcharts-chart>
      </mat-card-content>
      <ng-template #no_profile_data>
        <p class="align-center" id="vi_camer_profile_violation_chart_no_data">No data found</p>
      </ng-template>
    </mat-card>
    <mat-card class="no-link risk-events" id="vi_camer_profile_violation_chart_container">
      <mat-card-header class="risk-events-header" id="vi_camer_profile_violation_chart_header">
        <mat-card-title id="vi_camer_profile_violation_chart_title"> Event status </mat-card-title>
        <mat-card-subtitle class="sub-label">Last 7 days</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="risk-chart" id="vi_camer_profile_violation_chart">
        <highcharts-chart
          *ngIf="violationOptions.series && violationOptions.series.length > 0; else violation_no_data"
          [Highcharts]="Highcharts"
          [options]="violationOptions"
          [(update)]="updateViolationChart"></highcharts-chart>
      </mat-card-content>
      <ng-template #violation_no_data>
        <p class="align-center" id="vi_camer_profile_violation_no_data">No data found</p>
      </ng-template>
    </mat-card>
    <mat-card class="no-link risk-events" id="vi_camer_profile_risk_chart_container">
      <mat-card-header class="risk-events-header" id="vi_camer_profile_risk_chart_header">
        <mat-card-title id="vi_camer_profile_risk_chart_title"> At risk events </mat-card-title>
        <mat-card-subtitle class="sub-label">Last 7 days</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="risk-chart" id="vi_camer_profile_risk_chart">
        <highcharts-chart
          *ngIf="eventOptions.series && eventOptions.series.length > 0; else risk_no_data"
          [Highcharts]="Highcharts"
          [options]="eventOptions"
          [(update)]="updateRiskChart"></highcharts-chart>
      </mat-card-content>
      <ng-template #risk_no_data>
        <p class="align-center" id="vi_camer_profile_violation_chart_no_data">No data found</p>
      </ng-template>
    </mat-card>
  </div>
</div>
<app-alerts-container
  id="vi_camer_profile_alerts"
  *ngIf="!isLoading && cameraProfileData?.cameraDetails?.groupID"
  [groupId]="cameraProfileData.cameraDetails.groupID"
  (setReviewCategory)="setReviewCategory($event)"></app-alerts-container>
<slb-toast></slb-toast>
<ng-template #loader>
  <app-spinner *ngIf="isLoading || reviewLoader"></app-spinner>
</ng-template>
