import * as Highcharts from 'highcharts';

export const BARCHARTOPTIONS: Highcharts.Options = {
  chart: {
    type: 'column',
    backgroundColor: '#fcfcfd',
    spacing: [15, 0, 10, 0],
  },
  title: {
    text: '',
  },
  xAxis: {
    categories: ['Gateways', 'Camera'],
    labels: {
      y: 15,
      style: {
        fontFamily: "'SLBSans', 'Helvetica Neue', sans-serif",
        fontSize: '10px',
      },
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Count',
    },
    stackLabels: {
      enabled: true,
    },
  },
  legend: {
    align: 'left',
    verticalAlign: 'top',
    symbolRadius: 0,
    itemStyle: {
      fontFamily: "'SLBSans', 'Helvetica Neue', sans-serif",
      fontSize: '10px',
      fontWeight: '500',
      padding: '10',
    },
    reversed: true,
  },
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      borderWidth: 0,
    },
  },
  series: [],
};

export const LINECHARTOPTIONS: Highcharts.Options = {
  chart: {
    backgroundColor: '#fcfcfd',
    spacing: [0, 0, 0, -3],
    marginRight: 5,
  } as Highcharts.ChartOptions,
  title: {
    text: '',
  },
  time: { useUTC: false },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%b %e',
    },
    labels: {
      style: {
        fontFamily: "'SLBSans', 'Helvetica Neue', sans-serif",
        fontSize: '10px',
      },
    },
    title: {},
    gridLineColor: '#333',
  },
  yAxis: [
    {
      title: {
        text: 'No. of events',
        style: {
          fontFamily: "'SLBSans', 'Helvetica Neue', sans-serif",
          fontSize: '12px',
          fontWeight: '700',
        },
      },
      labels: {
        style: {
          fontFamily: "'SLBSans', 'Helvetica Neue', sans-serif",
          fontSize: '10px',
        },
      },
    },
  ],
  tooltip: {
    distance: 40,
    outside: true,
  },
  legend: {
    align: 'left',
    verticalAlign: 'top',
    enabled: true,
    itemStyle: {
      fontFamily: "'SLBSans', 'Helvetica Neue', sans-serif",
      fontSize: '10px',
      fontWeight: '500',
    },
    reversed: true,
  },
  plotOptions: {
    line: {
      marker: {
        radius: 2,
      },
      lineWidth: 1,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      threshold: null,
    },
  },
  credits: {
    enabled: false,
  },
  series: [],
};

export const GLOBALLINECHARTOPTIONS: Highcharts.Options = {
  chart: {
    backgroundColor: '#fcfcfd',
    spacing: [0, 0, 0, -3],
    marginRight: 5,
  } as Highcharts.ChartOptions,
  title: {
    text: '',
  },
  time: { useUTC: false },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%b %e',
    },
    labels: {
      style: {
        fontFamily: "'SLBSans', 'Helvetica Neue', sans-serif",
        fontSize: '10px',
      },
    },
    title: {},
    gridLineColor: '#333',
  },
  yAxis: [
    {
      title: {
        text: 'No. of events',
        style: {
          fontFamily: "'SLBSans', 'Helvetica Neue', sans-serif",
          fontSize: '12px',
          fontWeight: '700',
        },
      },
      labels: {
        style: {
          fontFamily: "'SLBSans', 'Helvetica Neue', sans-serif",
          fontSize: '10px',
        },
      },
    },
  ],
  tooltip: {
    distance: 40,
    outside: true,
  },
  legend: {
    align: 'left',
    verticalAlign: 'top',
    enabled: true,
    itemStyle: {
      fontFamily: "'SLBSans', 'Helvetica Neue', sans-serif",
      fontSize: '14px',
      fontWeight: '500',
    },
    reversed: true,
  },
  plotOptions: {
    line: {
      marker: {
        radius: 2,
      },
      lineWidth: 1,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      threshold: null,
    },
  },
  credits: {
    enabled: false,
  },
  series: [],
};
