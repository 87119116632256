import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { CustomCalendarComponent } from '../custom-calendar/custom-calendar.component';
import { EventData, EventDataIteration, EventDetails, Media, WORKFLOW_DETAILS } from '../../shared/models/eventDetails';
import { CameraProfileService } from '../../shared/services/camera-profile.service';
import { BehaviorSubject, Subject, catchError, filter, of, switchMap, takeUntil, tap } from 'rxjs';
import { CameraStatus } from '../../shared/constants/enum';
import {
  EVENTERROR,
  EVENT_CSV_DOWNLOAD_HEADING,
  EVENT_FILTER,
  EXCLUDED,
  FILE_DOWNLOAD_ERROR,
  WORKFLOW,
  WORKFLOW_ALL,
} from '../../shared/constants/camera-profile-constant';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TimeZoneService } from '../../shared/services/time-zone.service';
import { TimeZone } from '../../shared/models/dashabordAlertData';
import { PopUpService } from '../../shared/services/pop-up.service';
import { DashboardService } from '../../shared/services/dashboard.service';
import { DashboardConstants } from '../../shared/constants/dashboard-constants';
import { CameraConstants } from '../../../app/shared/constants/camera-constant';

@Component({
  selector: 'app-alerts-container',
  templateUrl: './alerts-container.component.html',
  styleUrls: ['./alerts-container.component.scss'],
})
export class AlertsContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() groupId: string;
  @Output() setReviewCategory = new EventEmitter<string>();
  public reviewCategory = 'All';
  public violationData: EventDetails[];
  public viewMode = 'grid';
  public selectedFiles: EventDetails[];
  public checkAll: boolean;
  public violationDataList: EventDetails[];
  public dayMode: string;
  public startDate: Date;
  public endDate: Date;
  public controllerId: string;
  public gatewayId: string;
  public isLoading: boolean;
  public currentZone = '';
  public refreshedTimezone = '';
  public totalRecords = 0;
  public reviewFilterList = EVENT_FILTER;
  public paginatedData: PageEvent = JSON.parse(JSON.stringify(DashboardConstants.DEFAULT_PAGINATION));
  public isDownloadStarted = false;
  public strikeRedzone = false;
  public strikePPE = false;
  public noEventData: boolean;
  public eventId: number;
  public workflows = WORKFLOW;
  private eventStartDate: string;
  private SECONDS_IN_HOUR = 3600 * 1000;
  private HOUR = 1;
  private DAY = 24;
  private WEEK = 168;
  private cameraName: string;
  private destroyed = new Subject();
  private previousDaymode: string;
  private pageNumber: number;
  private recordsPerPage: number;
  private eventTime: Date;
  private fromDashboard = false;
  private event: string[];
  private gridState = new BehaviorSubject(this.paginatedData);
  private gridState$ = this.gridState.asObservable();
  private cameraStatus: CameraStatus;
  private rigName: string;
  private showAllEvents: string;
  private isPaginated = false;
  private isAdmin: boolean;

  constructor(
    private dialog: MatDialog,
    private cameraProfileService: CameraProfileService,
    private dashboardService: DashboardService,
    private timeZoneService: TimeZoneService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private popupService: PopUpService,
    private datePipe: DatePipe
  ) {
    this.violationDataList = this.violationData;
    this.route.params
      .pipe(
        tap(params => {
          if (params) {
            this.gatewayId = params.gateway;
            this.controllerId = params.controllerId;
            this.cameraName = params.cameraName;
            this.cameraStatus = params.cameraStatus;
            this.rigName = params.rigName;
          }
        }),
        switchMap(() =>
          this.timeZoneService.timeZoneDetails$.pipe(
            filter((zone: TimeZone) => !!Object.keys(zone).length),
            tap((_zone: TimeZone) => (this.currentZone = this.timeZoneService.getTimeZone()))
          )
        ),
        switchMap(() =>
          this.cameraProfileService.selectedEventTime$.pipe(
            tap((eventTime: string) => {
              if (eventTime) {
                this.fromDashboard = true;
                this.eventTime = new Date(eventTime);
              } else if (sessionStorage.getItem('selectedCameraEventDate')) {
                const sessionEventTime = sessionStorage.getItem('selectedCameraEventDate')?.toString();
                if (sessionEventTime) {
                  this.fromDashboard = true;
                  this.eventTime = new Date(sessionEventTime);
                }
              }
            })
          )
        ),
        switchMap(() =>
          this.cameraProfileService.isAdmin$.pipe(
            tap(isAdminData => {
              if (!isAdminData || isAdminData === 'false') {
                this.reviewFilterList = this.reviewFilterList.filter(review => review.toLowerCase() !== EXCLUDED);
              }
            })
          )
        ),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.getCameraWorkflow();
    this.getqueryParameters();
    this.isLoading = true;
    this.selectedFiles = [];
    this.noEventData = false;
    this.event = WORKFLOW_ALL;
    this.pageNumber = DashboardConstants.PAGE_NUMBER;
    this.recordsPerPage = DashboardConstants.RECORDS_PER_PAGE;
    if (!this.fromDashboard) {
      this.dayMode = 'day';
      this.previousDaymode = 'day';
      this.dayMode = this.eventId === 0 ? this.dayMode : 'custom';

      let endDate = new Date(this.eventStartDate);
      endDate = new Date(endDate.setSeconds(endDate.getSeconds() + 2));
      this.endDate = this.eventId === 0 ? new Date() : endDate;

      let startDate = new Date(this.eventStartDate);
      startDate = new Date(startDate.setHours(startDate.getHours() - 24));
      this.startDate = this.eventId === 0 ? new Date() : startDate;
    } else {
      this.checkTimeRange();
    }
  }

  ngAfterViewInit(): void {
    if (!this.fromDashboard) {
      this.generateDate();
      this.getEvent();
    }
  }

  public switchView(event: { value: string }): void {
    this.viewMode = event.value;
  }

  public downloadSelectedFile(): void {
    const downloadUrls: number[] = [];
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      this.selectedFiles.forEach(file => {
        const medias = file.media.filter((media: Media) => media.fileUrl);
        if (medias.length) {
          downloadUrls.push(file.eventId);
        }
      });
      if (downloadUrls && downloadUrls.length > 0) {
        this.downloadFiles(downloadUrls);
      } else {
        this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
      }
    }
  }

  public async openReviewPopup(): Promise<void> {
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      await this.popupService.openEditReviewConfiguration(this.selectedFiles);
    }
  }

  public getSelectedFiles(value: EventDetails[]): void {
    this.selectedFiles = value;
    if (this.violationDataList.length > this.selectedFiles.length) {
      this.checkAll = false;
    } else if (this.violationDataList.length === this.selectedFiles.length) {
      this.checkAll = true;
    }
    this.updateSelection();
  }

  public checkAllFiles(): void {
    if (this.checkAll) {
      this.selectedFiles = this.violationDataList;
      this.violationDataList.map((data: EventDetails) => (data['isChecked'] = true));
      this.violationDataList = [...this.violationDataList];
    } else {
      this.violationDataList.map((data: EventDetails) => (data['isChecked'] = false));
      this.violationDataList = [...this.violationDataList];
      this.selectedFiles = [];
    }
    this.updateSelection();
  }

  public switchDays(event: { value: string }): void {
    this.previousDaymode = this.dayMode;
    this.dayMode = event.value;
    if (this.dayMode !== 'custom') {
      this.isLoading = true;
      this.selectedFiles = [];
      this.pageNumber = DashboardConstants.PAGE_NUMBER;
      this.recordsPerPage = DashboardConstants.RECORDS_PER_PAGE;
      this.totalRecords = 0;
      this.paginatedData = JSON.parse(JSON.stringify(DashboardConstants.DEFAULT_PAGINATION));
      this.generateDate();
    }
  }

  public customClick(): void {
    const dialogRef = this.dialog.open(CustomCalendarComponent, {
      width: '550px',
      data: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.type === 'save') {
        this.startDate = result.dates.startDate;
        this.endDate = result.dates.endDate;
        this.isLoading = true;
        this.selectedFiles = [];
        this.pageNumber = DashboardConstants.PAGE_NUMBER;
        this.recordsPerPage = DashboardConstants.RECORDS_PER_PAGE;
        this.totalRecords = 0;
        this.paginatedData = JSON.parse(JSON.stringify(DashboardConstants.DEFAULT_PAGINATION));
        this.gridState.next(this.paginatedData);
        this.previousDaymode = 'custom';
      } else {
        this.dayMode = this.previousDaymode;
      }
    });
  }

  public getPaginatedData(event: PageEvent): void {
    if (this.pageNumber !== event.pageIndex + 1 || this.recordsPerPage !== event.pageSize) {
      this.paginatedData = event;
      this.pageNumber = event.pageIndex + 1;
      this.recordsPerPage = event.pageSize;
      this.selectedFiles = [];
      this.gridState.next(this.paginatedData);
    }
  }

  public getFilteredReviews(): void {
    this.pageNumber = 1;
    this.recordsPerPage = DashboardConstants.RECORDS_PER_PAGE;
    this.totalRecords = 0;
    this.selectedFiles = [];
    this.paginatedData = JSON.parse(JSON.stringify(DashboardConstants.DEFAULT_PAGINATION));
    this.setReviewCategory.emit(this.reviewCategory);
    this.gridState.next(this.paginatedData);
  }

  public assignEvent(): void {
    this.pageNumber = 1;
    const getNCWorkflow = this.workflows.filter(data => !data.checked);
    if (getNCWorkflow.length === 0) {
      this.noEventData = true;
      this.violationDataList = [];
      this.event = [];
    } else {
      if (getNCWorkflow.length === this.workflows.length) {
        this.event = WORKFLOW_ALL;
      } else {
        this.event = getNCWorkflow.map(data => data?.value);
      }
      this.gridState.next(this.paginatedData);
    }
    this.paginatedData.pageIndex = 0;
  }

  public downloadCsvEventData(): void {
    const startTime = this.calculateTimeBasedOnTimezone(this.startDate);
    const endTime = this.calculateTimeBasedOnTimezone(this.endDate);
    const recordsPerPage = 50;
    const pageNumber = 1;
    this.isDownloadStarted = true;
    this.messageService.add({ severity: SlbSeverity.Info, summary: CameraConstants.CSV_DOWNLOAD_WAIT_MSG, sticky: true, closable: false });
    let csvData = '';
    let blob = new Blob();

    this.cameraProfileService
      .getAllEventData(
        this.gatewayId,
        this.controllerId,
        startTime,
        endTime,
        pageNumber,
        recordsPerPage,
        this.reviewCategory.toLowerCase(),
        this.event,
        this.showAllEvents
      )
      .pipe(
        tap((value: EventDataIteration) => {
          const columnNames = EVENT_CSV_DOWNLOAD_HEADING;

          if (!value?.totalRecords || !columnNames?.length) {
            return;
          }
          const eventData: EventDetails[] = JSON.parse(JSON.stringify(value.data));
          csvData = this.convertToCsv(csvData, eventData, columnNames);

          blob = new Blob([blob, '\ufeff' + csvData], {
            type: 'text/csv;charset=utf-8;',
          });
          this.isDownloadStarted = false;
          if (value.index === value.totalPages - 1) {
            this.downloadData(blob);
          }
        }),
        catchError(() => {
          this.messageService.clear();
          this.messageService.add({ severity: SlbSeverity.Error, summary: CameraConstants.CSV_DOWNLOAD_ERROR_MSG });
          this.isDownloadStarted = false;

          return of<EventDataIteration>({} as EventDataIteration);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public setEventId(): void {
    this.eventId = 0;
  }

  public getLabel(eventDetails: EventDetails | null | undefined): string {
    if (eventDetails) {
      return this.dashboardService.setLabel(eventDetails);
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.cameraProfileService.setEventTime('');
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  private downloadFiles(eventId: number[]): void {
    this.cameraProfileService
      .downloadMediaAsZip(this.gatewayId, this.controllerId, eventId)
      .pipe(
        tap((data: string) => {
          if (data && data !== '') {
            this.downloadZipFile(data);
          } else {
            this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
          }
        }),
        catchError(err => {
          console.error(err);
          throw err;
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private generateDate(): void {
    switch (this.dayMode) {
      case 'hour':
        this.getDate(this.HOUR);
        break;
      case 'day':
        this.getDate(this.DAY);
        break;
      case 'week':
        this.getDate(this.WEEK);
        break;
    }
    if (this.event.length > 0) {
      this.gridState.next(this.paginatedData);
    } else {
      this.isLoading = false;
    }
  }

  private getDate(hour: number): void {
    if (hour) {
      this.endDate = new Date();
      this.startDate = new Date(new Date().getTime() - hour * this.SECONDS_IN_HOUR);
    }
  }

  private getEvent(): void {
    let startTime: string;
    let endTime: string;
    this.isLoading = true;
    this.gridState$
      .pipe(
        tap(() => {
          this.isPaginated = true;
        }),
        switchMap(() =>
          this.cameraProfileService.eventData$.pipe(
            tap(_value => {
              this.isLoading = true;
              this.showAllEvents = sessionStorage.getItem('showAllEvents') || '';
              startTime = this.calculateTimeBasedOnTimezone(this.startDate);
              endTime = this.calculateTimeBasedOnTimezone(this.endDate);
              this.selectedFiles = [];
              this.checkPagination(_value);
            }),
            switchMap(() =>
              this.cameraProfileService
                .getCameraEventData(
                  this.gatewayId,
                  this.controllerId,
                  startTime,
                  endTime,
                  this.pageNumber,
                  this.recordsPerPage,
                  this.reviewCategory.toLowerCase(),
                  this.event,
                  this.showAllEvents
                )
                .pipe(
                  tap((eventData: EventData) => {
                    if (eventData?.data) {
                      if (this.event.length > 0) {
                        this.setEventData(eventData);
                      }
                    }
                    this.isLoading = false;
                  }),
                  catchError(() => {
                    this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTERROR });
                    this.isLoading = false;

                    return of<string>('');
                  }),
                  takeUntil(this.destroyed)
                )
            )
          )
        ),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private checkPagination(value: boolean): void {
    if (!this.isPaginated && value) {
      this.paginatedData.pageIndex = 0;
      this.paginatedData.pageSize = DashboardConstants.RECORDS_PER_PAGE;
      this.paginatedData.length = DashboardConstants.PAGE_NUMBER;
      this.recordsPerPage = DashboardConstants.RECORDS_PER_PAGE;
      this.pageNumber = DashboardConstants.PAGE_NUMBER;
    } else {
      this.isPaginated = false;
    }
  }

  private updateSelection(): void {
    this.violationDataList.forEach((data: EventDetails) => {
      data.isChecked = false;
    });
    this.selectedFiles.forEach((file: EventDetails) => {
      this.violationDataList.forEach((data: EventDetails) => {
        if (file.eventId === data.eventId) {
          data.isChecked = true;
        }
      });
    });
  }

  private downloadZipFile(url: string): void {
    const blob = new Blob([url], {
      type: 'application/zip',
    });
    const bloburl = window.URL.createObjectURL(blob);
    const filename = 'SLB Edge-Vision Intelligence-' + this.controllerId;
    const dwldLink = document.createElement('a');

    dwldLink.setAttribute('href', bloburl);
    dwldLink.setAttribute('download', filename + '.zip');
    dwldLink.setAttribute('target', '_blank');
    dwldLink.click();
    dwldLink.remove();
  }

  private calculateTimeBasedOnTimezone(date: Date): string {
    if (date) {
      const dateBdOnChosedTimezone = new DatePipe('en-Us').transform(date, 'EEEE, MMMM d, y, h:mm:ss a zzzz', this.currentZone);
      if (dateBdOnChosedTimezone) {
        const basedonCurrentTimezone: Date = new Date(dateBdOnChosedTimezone);

        return basedonCurrentTimezone.toISOString();
      }

      return '';
    }

    return '';
  }

  private checkTimeRange(): void {
    const eventTimeinms = this.eventTime.getTime();
    if (eventTimeinms > new Date().getTime() - this.SECONDS_IN_HOUR) {
      this.dayMode = 'hour';
      this.getDate(this.HOUR);
    } else if (eventTimeinms > new Date().getTime() - 24 * this.SECONDS_IN_HOUR) {
      this.dayMode = 'day';
      this.getDate(this.DAY);
    } else if (eventTimeinms > new Date().getTime() - 168 * this.SECONDS_IN_HOUR) {
      this.dayMode = 'week';
      this.getDate(this.WEEK);
    } else {
      this.dayMode = 'custom';
      this.endDate = new Date(eventTimeinms);
      this.startDate = new Date(this.endDate.getTime() - 24 * this.SECONDS_IN_HOUR);
    }
    this.getEvent();
  }

  private downloadData(blob: Blob): void {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    const filename = `${this.cameraName.replace(' ', '')}-EventDetails-${new Date().toLocaleString()}`;

    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.isDownloadStarted = false;
    this.messageService.clear();
  }

  private getqueryParameters(): void {
    let queryParams = sessionStorage.getItem('eventlink');
    this.eventStartDate = new Date().toISOString();
    if (queryParams?.includes('eventid')) {
      let arr = queryParams?.split('?');
      queryParams = arr[1];
      arr = queryParams.split('&');
      arr.forEach(element => {
        const param = element.split('=');
        if (param[0] === 'eventid') {
          this.eventId = Number(param[1]);
        } else if (param[0] === 'eventdate') {
          this.eventStartDate = new Date(Number(param[1].toString())).toISOString();
        }
      });
    }
    sessionStorage.removeItem('eventlink');
  }

  private setEventData(eventData: EventData): void {
    this.violationDataList = eventData?.data;
    this.totalRecords = eventData?.totalRecords;
    this.checkAll = false;
    this.violationDataList?.forEach(data => {
      data.cameraStatus = this.cameraStatus;
      data.cameraName = this.cameraName;
      data.rigName = this.rigName;
      data.groupId = this.groupId;
      data.rigState = this.dashboardService.getRigEventDetails(data);
      if (data?.isChecked) {
        data.isChecked = false;
      }

      return data;
    });
  }

  private convertToCsv(prevCsvData: string, objArray: EventDetails[], headerList: string[]): string {
    let str = '';
    if (!prevCsvData) {
      let row = '';
      headerList.forEach((value: string) => {
        row += value.charAt(0).toUpperCase() + value.slice(1) + ',';
      });
      row = row.slice(0, -1);
      str += row + '\r\n';
    }

    str += this.extractData(objArray, headerList, this.currentZone);

    return str;
  }

  private extractData(objArray: EventDetails[], headerList: string[], currentZone: string): string {
    let str = '';

    objArray.forEach((eventValues: EventDetails) => {
      let line = '';
      headerList.forEach((header: string) => {
        line += this.getPropertyValue(eventValues, header, currentZone) + ',';
      });
      line = line.replace(/,\s*$/, '');
      str += line + '\r\n';
    });

    return str;
  }

  private getPropertyValue(value: EventDetails, propertyValue: string, currentZone: string): string {
    switch (propertyValue) {
      case 'peopleCount':
        return value?.peopleCount?.toString() ?? '0';

      case 'reason':
        if (value.rigState) {
          value.rigState = this.dashboardService.getRigEventDetails(value);
        }
        if (
          !value.rigState ||
          value.rigState === CameraConstants.NoRigData ||
          (value && value.peopleInsideRedZone === 0 && value.ppeViolations === 0 && value.catWalkViolations === 0)
        ) {
          return CameraConstants.NoRigData;
        } else {
          let tag = this.getLabel(value);
          tag += ': ' + value.rigState;
          tag = tag.replace(/,/g, '|');

          return tag;
        }
      case 'isReviewed':
        return value.isReviewed ? CameraConstants.Yes : CameraConstants.No;

      case 'reviewComment':
        const reviewValue = value[propertyValue] ? value[propertyValue].replace(/\n/g, ' ') : '';

        return reviewValue;

      case 'type':
        return this.getWorkflowType(value);

      case 'timestamp':
        const dateconversion = this.datePipe.transform(new Date(value.timestamp), 'MM/dd/yyyy hh:mm:ss a', currentZone);

        return dateconversion ? dateconversion : '';

      case 'imageFilename':
        const image = value.media.find(x => x.type === 'image');

        return image ? image.fileUrl.split('download/')[1] : '';

      case 'videoFilename':
        const video = value.media.find(x => x.type === 'video');

        return video ? video.fileUrl.split('download/')[1] : '';

      default:
        return value ? (value[propertyValue as keyof EventDetails] as string) : '';
    }
  }

  private getWorkflowType(value: EventDetails): string {
    if (value?.peopleInsideRedZone > 0) {
      return DashboardConstants.EVENTTYPE[1];
    } else if (value?.ppeViolations > 0) {
      return DashboardConstants.EVENTTYPE[0];
    } else if (value?.catWalkViolations > 0) {
      return DashboardConstants.EVENTTYPE[2];
    }

    return '';
  }

  private getCameraWorkflow(): void {
    this.cameraProfileService.cameraWorkflow$
      .pipe(
        tap((workflow: string[]) => {
          let filterworkflow = [] as unknown as WORKFLOW_DETAILS[];
          if (workflow.length && this.workflows.length) {
            filterworkflow = this.workflows.filter(data => workflow.includes(data.value.toLowerCase()));
          }
          this.workflows = filterworkflow;
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }
}
