<div class="event-container">
  <div class="header">
    <div class="left-header">
      <mat-icon svgIcon="arrow-left-3" class="header-back-arrow" id="latest_camera_popover_close_icon_fullscreen" (click)="onClose()"
        >close</mat-icon
      >
      <p>
        <mat-icon
          svgIcon="oil-bitumen"
          [ngClass]="{
            active: gatewayStatus === 0,
            inactive: gatewayStatus === 1,
            disconnect: gatewayStatus === 2
          }"
          id="latest_camera_popover_oil_bitumen_icon_fullscreen"></mat-icon>
        <span class="header-label">Site: </span>
        <span matTooltip="{{ cameraDetails.rigName }}"> {{ cameraDetails.rigName }} </span>
      </p>
      <p>
        <mat-icon
          svgIcon="oil-bitumen"
          [ngClass]="{
            active: cameraStatus === 0,
            inactive: cameraStatus === 1,
            disconnect: cameraStatus === 2
          }"
          id="latest_camera_popover_oil_bitumen_icon_fullscreen"></mat-icon>
        <span class="fullscreen-camera-title" id="latest_camera_popover_rig_title_fullscreen" matTooltip="{{ cameraDetails.cameraName }}">{{
          cameraDetails.cameraName
        }}</span>
      </p>
      <p>
        <span *ngIf="cameraDetails.isReviewed" class="fullscreen-review-icon">
          <mat-icon class="check-icon" svgIcon="check">check</mat-icon>
        </span>
        <span *ngIf="!cameraDetails.isReviewed" class="fullscreen-review-icon">
          <mat-icon class="check-icon" svgIcon="checks"></mat-icon>
        </span>
        <span class="header-label">{{ getLabelHeader(cameraDetails) }}</span>
        <span matTooltip="{{ cameraDetails.timestamp | date : 'medium' : currentZone }}" class="fullscreen-camera-timestamp">{{
          cameraDetails.timestamp | date : 'medium' : currentZone
        }}</span>
      </p>
    </div>
    <div class="right-header">
      <p>
        <button slb-secondary-button [slbTooltip]="template" class="share-action-button">
          <mat-icon svgIcon="share" class="share-button-icon"></mat-icon><span class="share-button-icon-text"> Share</span>
        </button>
        <mat-icon svgIcon="close" class="close-icon cursor-pointer" id="latest_camera_popover_close_icon_fullscreen" (click)="onClose()"
          >close</mat-icon
        >
      </p>
    </div>
    <ng-template #template>
      <div class="share-tooltip">
        <div class="share-tooltip-options" (click)="openEmail()">
          <mat-icon svgIcon="send-2" class="share-tooltip-icon"></mat-icon><span class="share-tooltip-label">Send email</span>
        </div>
        <div class="share-tooltip-options" (click)="downloadImages()">
          <mat-icon svgIcon="download" class="share-tooltip-icon"></mat-icon><span class="share-tooltip-label">Download</span>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="container">
    <div class="left-container">
      <app-carousel
        [isImgLoad]="$isImgLoad"
        [media]="media"
        [showIndicators]="false"
        [selectedIndex]="currentIndex"
        [showControls]="true"
        (activeIndex)="setCurrentIndex($event)">
        <ng-template slbTemplate="item" let-item>
          <div class="carousel-item-wrapper">
            <div class="fullscreen-image-container" *ngIf="item.type === 'image'">
              <img
                class="image-item fullscreen-image-src active"
                (error)="img.src = '../../assets/no-prev.png'"
                priority
                [src]="item.fileUrl + '/?token=' + sessionToken"
                #img
                appImageStyle
                width="100%"
                height="100%"
                *ngIf="item.fileUrl && !item.fileUrl.includes('mp4'); else errorImage" />
              <mat-icon
                *ngIf="item.type === 'image'"
                class="img-fullscreen-icon"
                id="latest_camera_popover_grid_fullscreen_icon"
                (click)="showImageInFullscreen(item.fileUrl)"
                svgIcon="fullscreen"
                title="Fullscreen"></mat-icon>
              <ng-template #errorImage>
                <img
                  *ngIf="item.type === 'image'"
                  id="latest_camera_popover_fullscreen_image_item"
                  src="../../assets/no-prev.png"
                  priority
                  appImageStyle
                  class="image-item" />
              </ng-template>
            </div>
            <div class="fullscreen-image-container" *ngIf="item.type === 'video'">
              <video class="full-screen-video-src" autoplay- controls loop>
                <source src="{{ item.fileUrl }}/?token={{ sessionToken }}" type="video/mp4" />
                <track id="enTrack" label="English" kind="subtitles" srclang="en-us" default />
              </video>
            </div>
          </div>
        </ng-template>
      </app-carousel>
      <div class="comment-content-container">
        <ng-container *ngTemplateOutlet="commentContent"></ng-container>
      </div>
      <div class="content">
        <p
          *ngIf="
            !cameraDetails ||
              !cameraDetails.rigState ||
              cameraDetails.rigState === 'Red zone static' ||
              (cameraDetails && !cameraDetails.peopleInsideRedZone && !cameraDetails.ppeViolations && !cameraDetails.catWalkViolations);
            else rigData
          ">
          Red zone static
        </p>
        <ng-template #rigData>
          <p class="camera-info">
            <span class="label">{{ getLabel(cameraDetails) }}: </span>
            <span matTooltip="{{ cameraDetails.rigState }}" class="rig-info">{{ cameraDetails.rigState }}</span>
          </p>
          <p class="camera-info">
            <span class="label">{{ cameraDetails && cameraDetails.peopleInsideRedZone > 0 ? 'Event Duration(s): ' : '' }}</span>
            <span
              matTooltip="{{ cameraDetails.duration }}"
              class="rig-info"
              *ngIf="cameraDetails && cameraDetails.peopleInsideRedZone > 0"
              >{{ cameraDetails.duration }}</span
            >
          </p>
          <p class="camera-info">
            <span class="label" *ngIf="cameraDetails && cameraDetails.peopleInsideRedZone > 0">{{
              cameraDetails && cameraDetails.peopleInsideRedZone > 0 ? 'People Count: ' : ''
            }}</span>
            <span
              matTooltip="{{ cameraDetails.peopleInsideRedZone }}"
              class="rig-info"
              *ngIf="cameraDetails && cameraDetails.peopleInsideRedZone > 0"
              >{{ cameraDetails.peopleCount }}</span
            >
          </p>
        </ng-template>
      </div>
      <div class="fullscreen-footer">
        <div class="actions">
          <button
            slb-secondary-button
            class="action-buttons"
            id="latest_camera_popover_previous_fullscreen"
            (click)="onNextPrevClick('prev')"
            [disabled]="!hasPrevious">
            <mat-icon svgIcon="dock-navigation-expand" class="button-icons"></mat-icon>
            Previous
          </button>
          <button
            slb-secondary-button
            (click)="onNextPrevClick('next')"
            id="latest_camera_popover_next_fullscreen"
            class="next-action-buttons"
            [disabled]="!hasNext">
            Next <mat-icon svgIcon="dock-navigation-collapse" class="next-button-icons"></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="right-container">
      <div class="btn-div">
        <div class="view-text">
          <mat-icon svgIcon="preview" class="view-icon"></mat-icon>
          <span class="view-label">{{ viewCount }} Views</span>
        </div>
        <button
          slb-secondary-button
          [ngClass]="{ 'flag-blue': selectedFlagButton !== 'valid', 'flag-green': selectedFlagButton === 'valid' }"
          id="agora_vi_event_review_save_btn"
          class="valid-button"
          (click)="setFlagData('valid')">
          <mat-icon svgIcon="thumbs-up" class="view-icon"></mat-icon>
          <span class="view-label">Valid ({{ validCount }})</span>
        </button>
        <button
          slb-secondary-button
          [ngClass]="{ 'flag-blue': selectedFlagButton !== 'invalid', 'flag-green': selectedFlagButton === 'invalid' }"
          id="agora_vi_event_review_save_btn"
          class="valid-button"
          (click)="setFlagData('invalid')">
          <mat-icon svgIcon="thumbs-down" class="view-icon"></mat-icon>
          <span class="view-label">Invalid ({{ invalidCount }}) </span>
        </button>
        <div *ngIf="isExclude">
          <button
            slb-secondary-button
            [ngClass]="{ 'flag-blue': selectedFlagButton !== 'exclude', 'flag-green': selectedFlagButton === 'exclude' }"
            id="agora_vi_event_review_save_btn"
            class="valid-button"
            (click)="setFlagData('exclude')">
            <mat-icon svgIcon="remove" class="view-icon"></mat-icon>
            <span class="view-label">Exclude ({{ excludeCount }})</span>
          </button>
        </div>
      </div>
      <div class="text-area">
        <mat-form-field slbFormField class="comments-txt-area">
          <textarea
            matInput
            class="comment"
            cdkFocusInitial
            type="textarea"
            [(ngModel)]="comment"
            (input)="checkComment()"
            id="agora_vi_event_review_comment_textarea"
            maxlength="2000"
            placeholder="Write your thoughts"></textarea>
        </mat-form-field>
        <button slb-secondary-button id="agora_vi_event_review_save_btn" [disabled]="isPostDisabled" (click)="postComment()" class="save">
          Post
        </button>
      </div>
      <div class="chat-container" #scrollMsg>
        <ng-container *ngIf="!isLoading && reviewComments.length; else loader">
          <div *ngFor="let comment of reviewComments; let i = index" class="chat-message">
            <div class="message-container">
              <div class="user-container">
                <div class="user-icon">{{ getInitials(comment.username) }}</div>
                <span class="user-label">{{ comment.username }}</span>
                <span class="user-time"><strong class="user-time-initial">. </strong>{{ getTimeDifference(comment.createdAt) }}</span>
              </div>
              <div class="user-message"><span class="header-label">Comment:</span> {{ comment.comment }}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="img-background" *ngIf="isImgFullScreen">
    <div [ngClass]="{ show: isImgFullScreen, hide: !isImgFullScreen }" *ngIf="isImgFullScreen">
      <img [src]="fullScreeenImgSrc" />
      <button class="close-fullscreen" (click)="hideImageInFullscreen()">
        <mat-icon svgIcon="close" class="img-close-icon" id="latest_camera_popover_close_fullscreen">close</mat-icon>
      </button>
    </div>
  </div>
  <ng-template #commentContent>
    <div class="comment-list" *ngIf="cameraDetails.peopleInsideRedZone > 0 && commentDetails.length">
      <span class="comment-content" [ngClass]="{ red: item.color === 'red' }" *ngFor="let item of commentDetails">
        {{ item.label }} {{ item.unit }} {{ item.range }} Range: [{{ item.min }}, {{ item.max }}] Current {{ item.label }}
        {{ item.currentValue }}
      </span>
    </div>
  </ng-template>
  <ng-template #loader>
    <div class="spinner">
      <mat-spinner *ngIf="isLoading" [diameter]="40"></mat-spinner>
    </div>
  </ng-template>
</div>
