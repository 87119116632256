import { Component, OnInit } from '@angular/core';
import { NavItem } from '@slb-dls/angular-material/shared';
import { USER_ROUTERLINKS } from '../../../app/shared/constants/unit-constant';

@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss'],
})
export class UserSettingComponent implements OnInit {
  public routerLinks = USER_ROUTERLINKS;
  public secondaryNavigationItems: NavItem[];

  constructor() {}

  ngOnInit(): void {
    this.secondaryNavigationItems = JSON.parse(JSON.stringify(this.routerLinks));
  }
}
