import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { RadioButtonValue } from '@slb-dls/angular-material/radio-button-group';
import { ZoneSettingsConstants } from '../../shared/constants/zone-setting-constants';
import { ZoneConfig, ZoneSetting, ZoneSettingOptions } from '../../shared/models/zoneSetting';
import { ZoneService } from '../../shared/services/zone.service';
import { EMPTY, Observable, Subject, catchError, delay, expand, of, switchMap, takeUntil, tap } from 'rxjs';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { LoginService } from '@agora/agora-ui-library';
import { UserSettingsService } from '../../shared/services/user-settings.service';
import { UnitsData } from '../../shared/models/unit';
import { CameraProfileService } from '../../shared/services/camera-profile.service';
import { ZoneConfiguration } from '../../shared/models/zone-configuration';
import { ZoneConfigurationConstants } from '../../shared/constants/zone-config.constant';

@Component({
  selector: 'app-zone-setting',
  templateUrl: './zone-setting.component.html',
  styleUrls: ['./zone-setting.component.scss'],
})
export class ZoneSettingComponent implements OnInit, OnDestroy {
  @Input() workflow: string;
  @Input() zoneName: string;
  @Input() ppeEventsOptions: any;
  @Input() previewError: string;
  @Input() cameraStatus: string;
  @Input() zoneConfiguration: ZoneConfiguration;
  public redZoneTypes: RadioButtonValue[] = ZoneSettingsConstants.REDZONETYPE_RADIO_OPTIONS;
  public zoneSettings: ZoneSettingOptions[] = [];
  public currentRedZone: number;
  public zoneId: string;
  public userName: string;
  public userEmail: string;
  public controllerId: string;
  public groupdId: string;
  public existingZoneSetting: ZoneSetting;
  public showLoader = false;
  public status: string;
  public isValid: boolean[] = [];
  public inactiveGateway: boolean;
  public inactiveCamera: boolean;
  public isValueChange: boolean;
  public isEnableSiren = false;
  public updatedOn: string | null;
  public updatedBy: string;
  private destroyed = new Subject();
  private unitSelected: UnitsData;
  private init: number;
  private saveEnabled = false;
  private delay = 5000;

  constructor(
    public zoneService: ZoneService,
    private messageService: MessageService,
    private loginService: LoginService,
    private cameraService: CameraProfileService,
    private userSettingService: UserSettingsService
  ) {
    this.cameraService.selectedZoneId$
      .pipe(
        tap((params: ZoneConfig) => {
          if (params) {
            this.zoneId = params.zoneId;
            this.controllerId = params.deviceId;
            this.currentRedZone = params.redZoneType;
            this.groupdId = params.groupdId;
            if (this.groupdId && !this.saveEnabled) {
              this.saveEnabled = false;
              this.zoneSettings =
                this.groupdId !== 'bsp' && this.groupdId !== 'ksa' && this.groupdId !== 'adc'
                  ? JSON.parse(JSON.stringify(ZoneSettingsConstants.NONBSPTAGSOPTIONS))
                  : JSON.parse(JSON.stringify(ZoneSettingsConstants.BSPTAGSOPTIONS));
            }
          }
        }),
        switchMap(() =>
          this.userSettingService.unitSelected$.pipe(
            tap((value: string) => {
              this.unitSelected = JSON.parse(value);
            })
          )
        ),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.showLoader = false;
    this.inactiveCamera = false;
    this.inactiveGateway = false;
    this.isValueChange = false;
    this.zoneService.isValueChange = false;
    const gatewayStatus = sessionStorage.getItem('gatewayStatus');
    if (gatewayStatus) {
      this.inactiveGateway = gatewayStatus !== '0' ? true : false;
      if (!this.inactiveGateway) {
        this.inactiveCamera = this.cameraStatus !== '0' ? true : false;
      }
    }
    this.loginService.isUserLoggedIn$
      .pipe(
        tap(() => {
          this.userEmail = this.loginService.getUserInfo()?.email ?? '';
          this.userName = this.loginService.getUserInfo()?.name ?? '';
        })
      )
      .subscribe();
    this.getZoneSettings();
  }

  public getZoneSettings(): void {
    if (this.zoneId !== '') {
      this.showLoader = true;
      this.zoneService
        .getZoneSetting(this.zoneId)
        .pipe(
          catchError(() => {
            this.showLoader = false;

            return of<ZoneSetting>({} as ZoneSetting);
          }),
          tap((data: ZoneSetting) => {
            if (data) {
              this.existingZoneSetting = data;
              this.updatedBy = data.updateBy;
              this.updatedOn = data.updateTime ? data.updateTime : null;
              this.currentRedZone = data.zoneTypeId;
              this.updateZoneSetting(data.zoneSettingData);
              this.status = data.commandStatus ? data.commandStatus : '';
            }
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    } else {
      this.zoneSettings?.forEach(zone => {
        zone.unit = this.userSettingService.getUnit(zone.unit, this.unitSelected.unitData);
      });
    }
  }

  public updateZoneSetting(response: any): void {
    if (response) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      for (const key of Object.keys(response)) {
        if (key !== 'enable_siren') {
          const index = this.zoneSettings.findIndex(x => x.tagname === key);
          if (index !== -1) {
            if (response[key] && response[key] !== null) {
              this.zoneSettings[index].value = response[key];
            }
          }
        }
      }
      this.updateUnits();
      this.zoneService.isValueChange = false;
    } else {
      this.zoneSettings?.forEach(zone => {
        zone.unit = this.userSettingService.getUnit(zone.unit, this.unitSelected.unitData);
      });
    }
    this.showLoader = false;
  }

  public updateSirenToggle(response: any): void {
    if (response) {
      this.isEnableSiren = response.enable_siren === 0 ? false : true;
    }
  }

  public updateNewSettings(tags: any): any {
    this.zoneSettings.map(zone => {
      if (tags[zone.tagname]) {
        tags[zone.tagname] = zone.value;
      }
    });

    return tags;
  }

  public saveZoneSettings(): void {
    this.showLoader = true;
    const zoneSettingJson =
      this.groupdId === 'bsp' || this.groupdId === 'ksa' || this.groupdId === 'adc'
        ? ZoneSettingsConstants.bspTags
        : ZoneSettingsConstants.NonBSPTags;

    this.zoneSettings.forEach((element, index) => {
      const key = element['tagname'];
      const zoneUpdatedValue = this.userSettingService.conversionToSIUnits(Number(element.zoneUpdatedValue), element.unit).toString();
      if (zoneSettingJson.hasOwnProperty(element['tagname'])) {
        this.checkZoneSettingValid(Number(element['value']), index);
        zoneSettingJson[key] = zoneUpdatedValue;
      }
    });

    const zoneSettingRequest = {
      id: this.zoneId,
      equipmentID: this.controllerId,
      zoneTypeId: this.currentRedZone,
      zoneSettingData: zoneSettingJson,
      updateBy: this.userEmail,
      groupID: this.groupdId,
    } as ZoneSetting;

    if (this.zoneId !== '') {
      zoneSettingRequest.equipmentID = this.existingZoneSetting.equipmentID;
    }
    if (this.isValid) {
      this.zoneService
        .saveZoneSetting(zoneSettingRequest, this.zoneId)
        .pipe(
          tap(_response => {
            if (_response) {
              this.zoneService.isValueChange = false;
              this.saveEnabled = true;
              const zoneData = {
                deviceId: this.controllerId,
                groupdId: this.groupdId,
                zoneId: _response.id,
                redZoneType: _response.zoneTypeId,
              } as ZoneConfig;
              this.cameraService.setZoneId(zoneData);
              sessionStorage.setItem('config', JSON.stringify(zoneData));
              this.showLoader = false;
              this.messageService.add({
                severity: SlbSeverity.Success,
                summary: 'Success',
                detail: ZoneConfigurationConstants.ZONESETTINGSUCCESS,
              });
            }
            this.getCommandStatus();
          }),
          catchError(err => {
            this.showLoader = false;
            if (err) {
              this.messageService.add({
                severity: SlbSeverity.Info,
                summary: 'ERROR',
                detail: ZoneSettingsConstants.zone_errorMessage,
              });
              this.showLoader = false;
            }

            return of<string>('');
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  public checkZoneSettingValid(event: number, index: number): void {
    this.zoneService.isValueChange = true;
    if (event === null) {
      this.isValid[index] = false;
    } else {
      const pattern = new RegExp('^-?[0-9]*(?:.[0-9]{0,7})?$');
      const result = pattern.test(event.toString());
      this.isValid[index] = result;
    }
  }

  public enableSirenToggleChange(): void {
    this.zoneService.isValueChange = true;
  }

  public ppeSettingChange(): void {
    this.zoneService.isValueChange = true;
  }

  public ppeLoader(loaderState: boolean): void {
    this.showLoader = loaderState;
  }

  private getCommandStatus(): void {
    this.init = 0;
    const getCommandStatus = this.getZoneApi();
    getCommandStatus
      .pipe(
        expand((result: string) =>
          (!(
            result?.toLowerCase().includes('acknowledged') ||
            result?.toLowerCase().includes('timeout') ||
            result?.toLowerCase().includes('error')
          ) && this.init <= 15
            ? this.getZoneApi()
            : EMPTY
          ).pipe(delay(this.delay))
        ),
        catchError(err => {
          if (err) {
            this.messageService.add({ severity: SlbSeverity.Error, summary: ZoneSettingsConstants.zone_errorMessage });
          }

          return '';
        })
      )
      .subscribe();
  }

  private getZoneApi(): Observable<string> {
    this.init += 1;
    if (this.init === 1) {
      setTimeout(() => {
        return this.zoneService.getCommandStatus(this.controllerId, this.init > 15);
      }, this.delay);
    } else {
      return this.zoneService.getCommandStatus(this.controllerId, this.init > 15);
    }

    return of('');
  }

  private updateUnits(): void {
    if (this.unitSelected?.unitData) {
      this.zoneSettings?.forEach(zone => {
        const siUnit = zone.unit;
        zone.unit = this.userSettingService.getUnit(zone.unit, this.unitSelected.unitData);
        zone.zoneUpdatedValue = this.userSettingService.siToChosenUnit(zone.value, siUnit, zone.unit);
      });
    }
  }
}
