<div class="app-content" id="vi_dashboard">
  <div class="dashboard-timezone">
    <mat-form-field appearance="outline" floatLabel="always" id="vi_timezone_dropdown_dashboard">
      <mat-select
        [(ngModel)]="currentZoneDetails"
        (selectionChange)="onTimezoneChange()"
        class="timezone-field"
        hideSingleSelectionIndicator="true">
        <mat-option *ngFor="let zone of timezoneList" [value]="zone">
          {{ zone.label + ' ' + zone.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="country-field" appearance="outline" floatLabel="always" id="vi_country_dropdown_dashboard">
      <mat-select
        [(ngModel)]="countryName"
        class="timezone-field"
        (selectionChange)="onCountryChange()"
        [disabled]="disableCountry"
        hideSingleSelectionIndicator="true">
        <mat-option value=""> All </mat-option>
        <mat-option *ngFor="let country of countryList" [value]="country">
          {{ country }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="doughnut-charts-container" *ngIf="!summaryLoader; else chartLoader">
    <mat-card class="no-link" id="vi_total_gateway_chart_dashboard">
      <mat-card-header>
        <mat-card-title id="vi_total_gateway_chart_dashboard_header"
          >Total events: {{ totalEvents }}
          <mat-card-subtitle class="sub-label">Last 7 days</mat-card-subtitle>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="chart-container">
        <div class="event-chart" *ngIf="totalEvents; else no_data">
          <highcharts-chart [Highcharts]="Highcharts" [options]="eventLineChartOption" [(update)]="updateEventLineChart"></highcharts-chart>
        </div>
      </mat-card-content>
      <ng-template #no_data>
        <p class="align-center" id="vi_dashboard_gateways_no_data">No chart data is available</p>
      </ng-template>
    </mat-card>
    <mat-card class="no-link" id="vi_total_violation_chart_dashboard">
      <mat-card-header class="event-header">
        <mat-card-title id="vi_total_violation_chart_dashboard_header"> Event status</mat-card-title>
        <mat-card-subtitle class="sub-label">Last 7 days</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="chart-container">
        <div *ngIf="totalVoilation; else no_event_status">
          <highcharts-chart [Highcharts]="Highcharts" [options]="eventChartOptions" [(update)]="updateChart"></highcharts-chart>
        </div>
      </mat-card-content>
      <ng-template #no_event_status>
        <p class="align-center" id="vi_dashboard_cameras_no_data">No chart data is available</p>
      </ng-template>
    </mat-card>
    <mat-card class="no-link" id="vi_total_violation_chart_dashboard">
      <mat-card-header class="devicestatus-header">
        <mat-card-title id="vi_total_violation_chart_dashboard_header"> Device status </mat-card-title>
        <mat-card-subtitle class="sub-label"> </mat-card-subtitle>
        <div class="export-content">
          <a routerLink="/camera">
            <mat-icon svgIcon="expand-1" class="export-icon" id="vi_dashboard_export_icon"></mat-icon>
          </a>
        </div>
      </mat-card-header>
      <mat-card-content class="chart-container device-card">
        <div *ngIf="showDeviceOption; else violation_no_data">
          <highcharts-chart [Highcharts]="Highcharts" [options]="deviceStatusOptions" [(update)]="updateChart"></highcharts-chart>
        </div>
      </mat-card-content>
      <ng-template #violation_no_data>
        <p class="align-center" id="vi_camer_profile_violation_no_data">No chart data is available</p>
      </ng-template>
    </mat-card>
  </div>
  <ng-template #chartLoader>
    <mat-card>
      <mat-card-content class="doughnut-charts-loader"> <app-spinner></app-spinner> </mat-card-content>
    </mat-card>
  </ng-template>
  <div class="date-filters-content">
    <div class="dashboard-date-fields">
      <mat-form-field class="top" appearance="outline" floatLabel="always" id="vi_rig_name_filter_dashboard">
        <mat-label class="field-label" id="vi_rig_name_filter_dashboard_title"> Site </mat-label>
        <mat-select
          [(ngModel)]="alertFilter.rigId"
          hideSingleSelectionIndicator="true"
          [disabled]="alertsLoader"
          (selectionChange)="onFilterChange()">
          <mat-option [value]="-1"> All </mat-option>
          <mat-option *ngFor="let rig of rigList" [value]="rig.id">
            {{ rig.rigName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="top" appearance="outline" floatLabel="always" id="vi_workflow_filter_dashboard">
        <mat-label class="field-label" id="vi_workflow_filter_dashboard_title"> Workflow </mat-label>
        <mat-select
          [(ngModel)]="alertFilter.workflow"
          [disabled]="alertsLoader"
          hideSingleSelectionIndicator="true"
          (selectionChange)="onFilterChange()">
          <mat-option value=""> All </mat-option>
          <mat-option *ngFor="let workflow of workflowList" [value]="workflow">
            <span
              ><img src="{{ getWorkflowIcon(workflow) }}" id="vi_status-container_dashboard_redzone_icon" class="dropdown-icon" />
              {{ workflow }}
            </span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="top" appearance="outline" floatLabel="always" id="vi_gateway_filter_dashboard">
        <mat-label class="field-label" id="vi_gateway_filter_dashboard_title"> Gateway status </mat-label>
        <mat-select
          [(ngModel)]="alertFilter.gatewayStatus"
          hideSingleSelectionIndicator="true"
          [disabled]="alertsLoader"
          (selectionChange)="onFilterChange()">
          <mat-option *ngFor="let status of statusList" [value]="status.id">
            {{ status.status }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="top" appearance="outline" floatLabel="always" id="vi_camera_status_filter_dashboard">
        <mat-label id="vi_camera_status_filter_dashboard_title"> Camera status </mat-label>
        <mat-select
          [(ngModel)]="alertFilter.cameraStatus"
          [disabled]="alertsLoader"
          hideSingleSelectionIndicator="true"
          (selectionChange)="onFilterChange()">
          <mat-option *ngFor="let status of statusList" [value]="status.id">
            {{ status.status }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        class="filter-button"
        id="vi_dashboard_dropdown_filter_btn"
        slb-button
        id="vi_filter_dashboard"
        (click)="filterAlertList()"
        [disabled]="isFilter">
        Filter
      </button>
    </div>
    <div class="status-container" id="vi_status-container_dashboard">
      <div class="legend">
        <span class="status-title"> Status: </span>
        <span><mat-icon svgIcon="oil-bitumen" class="active-icon" id="vi_status-container_dashboard_active_icon"></mat-icon></span>
        <span class="status-title"> Active </span>
        <span><mat-icon svgIcon="oil-bitumen" class="inactive-icon" id="vi_status-container_dashboard_inactive_icon"></mat-icon></span>
        <span class="status-title"> Inactive </span>
        <span
          ><mat-icon svgIcon="oil-bitumen" class="disconnected-icon" id="vi_status-container_dashboard_disconnected_icon"></mat-icon
        ></span>
        <span class="status-title"> Disconnected </span>
        <ul class="workflow-list">
          <li id="vi_camera_profile_alerts" *ngFor="let workflow of workflows">
            <img src="{{ workflow.icon }}" class="card-notations workflow-image" id="vi_accordian_dashboard_table_container_redzone" />
            <label>{{ workflow.name }}</label
            ><br />
          </li>
        </ul>
      </div>
      <div class="toggle-container">
        <mat-button-toggle-group
          disableRipple="true"
          (change)="switchView($event)"
          [disabled]="alertsLoader || !rigData"
          [value]="viewMode"
          id="vi_view_toggle_dashboard">
          <mat-button-toggle value="grid" id="vi_view_toggle_dashboard_grid"> Grid view </mat-button-toggle>
          <mat-button-toggle value="table" id="vi_view_toggle_dashboard_table"> Table view </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="rig-content" id="vi_grid_view_dashboard" *ngIf="!alertsLoader; else gridLoader">
      <p *ngIf="!rigData?.length; else showGrid" class="no-data">No data available</p>
      <ng-template #showGrid>
        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let rig of rigData; let i = index"
            [expanded]="i === expandedIndex"
            class="rig-details"
            (opened)="getExpandedPanel(i)"
            id="vi_accordian_dashboard_rig_details">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="rig-name" id="vi_accordian_dashboard_title">Site: {{ rig.rigName }}</span>
              </mat-panel-title>
              <mat-panel-description>
                <div class="rig-legend">
                  <div class="panel-desc-column-1">
                    <span
                      ><mat-icon
                        svgIcon="oil-bitumen"
                        [ngClass]="{
                          active: rig.gatewayStatus === 0,
                          inactive: rig.gatewayStatus === 1,
                          disconnect: rig.gatewayStatus === 2
                        }"
                        id="vi_accordian_dashboard_bitumen_icon"></mat-icon
                    ></span>
                    <span class="rig-legend-text" id="vi_accordian_dashboard_rig_legend_text">{{ rig.gatewayName }}</span>
                  </div>
                  <div class="panel-desc-column-2">
                    <span>
                      <mat-icon
                        svgIcon="oil-bitumen"
                        [ngClass]="rig.rigDataStatus === 0 ? 'active-icon' : 'disconnected-icon'"
                        id="vi_accordian_dashboard_active-icon"></mat-icon>
                    </span>
                    <span class="rig-legend-text" id="vi_accordian_dashboard_rig_legend_text">Rig data</span>
                  </div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="card-container">
              <div *ngIf="viewMode === 'grid'" class="grid-card-container" id="vi_accordian_dashboard_grid_card_container">
                <mat-card class="grid-card" *ngFor="let camera of rig.cameras; let i = index">
                  <mat-card-header class="camera-header" id="vi_accordian_dashboard_grid_card_container_header">
                    <mat-card-title>
                      <mat-icon
                        svgIcon="oil-bitumen"
                        id="vi_accordian_dashboard_grid_card_container_bitumen_icon"
                        [ngClass]="{
                          active: camera.cameraStatus === 0,
                          inactive: camera.cameraStatus === 1,
                          disconnect: camera.cameraStatus === 2
                        }"></mat-icon
                      ><span class="rig-name" id="vi_accordian_dashboard_grid_card_container_rig_name" [matTooltip]="camera.name">{{
                        camera.name
                      }}</span>
                    </mat-card-title>
                    <mat-icon
                      svgIcon="preview"
                      class="preview-icon grid-preview-icon"
                      id="vi_accordian_dashboard_grid_card_container_preview_icon"
                      (click)="openCameraProfilePage(camera)"></mat-icon>
                  </mat-card-header>
                  <span fxLayoutAlign="center center" class="img-container" *ngIf="camera.eventDetails; else noEventImage">
                    <img
                      *ngIf="camera?.eventDetails?.thumbnailFileUrl; else errorImage"
                      mat-card-image
                      #eventImage
                      src="{{ camera?.eventDetails?.thumbnailFileUrl }}/?token={{ sessionToken }}"
                      class="image-src"
                      alt="IMAGE"
                      (error)="eventImage.src = '../../assets/no-preview.svg'"
                      id="vi_accordian_dashboard_grid_card_container_image_open"
                      (click)="onImageClick(camera, i, rig); dashaborddrawer.open()" />
                    <ng-template #errorImage>
                      <img
                        mat-card-image
                        src="../../assets/no-preview.svg"
                        class="image-src no-prev"
                        id="vi_accordian_dashboard_grid_card_container_image_open"
                        (click)="onImageClick(camera, i, rig); dashaborddrawer.open()" />
                    </ng-template>
                  </span>
                  <ng-template #noEventImage>
                    <span fxLayoutAlign="center center" class="img-container">
                      <img
                        mat-card-image
                        src="../../assets/no-event-detected.svg"
                        class="no-event-image-src image-src"
                        id="vi_accordian_dashboard_grid_card_container_image_no_event"
                        (click)="showNoEventToasterMesaage(camera)" />
                    </span>
                  </ng-template>
                  <mat-card-content
                    id="vi_accordian_dashboard_grid_card_container_camera_info"
                    class="content-container"
                    *ngIf="camera?.eventDetails">
                    <div class="content">
                      <p class="camera-info">
                        <span
                          *ngIf="
                            camera?.eventDetails?.rigState === 'Red zone static' ||
                              (camera?.eventDetails?.peopleInsideRedZone === 0 &&
                                camera?.eventDetails?.ppeViolations === 0 &&
                                camera?.eventDetails?.catWalkViolations === 0);
                            else rigData
                          ">
                          <span class="bg-setting people-redzone"></span>
                          <strong>Red zone static</strong>
                        </span>
                        <ng-template #rigData>
                          <img
                            src="{{ getIcon(camera?.eventDetails) }}"
                            class="card-notations"
                            id="vi_accordian_dashboard_grid_card_container_redzone" />
                          <span class="label">{{ getLabel(camera?.eventDetails) }}</span>
                          <span matTooltip="{{ camera.eventDetails?.rigState }}">: {{ camera?.eventDetails?.rigState }}</span>
                        </ng-template>
                      </p>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <mat-table [dataSource]="rig.cameras" *ngIf="viewMode === 'table'" class="dashboard-grid" id="vi_dashboard_grid">
                <!-- Camera Definition -->
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef> Camera </mat-header-cell>
                  <mat-cell *matCellDef="let data; let i = index" id="{{ 'vi_dashboard_grid_' + 'camera' + (i + 1) }}">
                    <mat-icon
                      svgIcon="oil-bitumen"
                      class="status-icon"
                      [ngClass]="{
                        active: data.cameraStatus === 0,
                        inactive: data.cameraStatus === 1,
                        disconnect: data.cameraStatus === 2
                      }"></mat-icon
                    >{{ data.name }}
                  </mat-cell>
                </ng-container>

                <!-- Red Zone violations Definition -->
                <ng-container matColumnDef="isRedzone">
                  <mat-header-cell *matHeaderCellDef> Event</mat-header-cell>
                  <mat-cell *matCellDef="let camera; let i = index" id="{{ 'vi_dashboard_grid_' + 'redZone_violations' + (i + 1) }}">
                    <p class="camera-info" *ngIf="camera.eventDetails">
                      <span
                        *ngIf="
                          camera?.eventDetails?.rigState === 'Red zone static' ||
                            (camera?.eventDetails?.peopleInsideRedZone === 0 &&
                              camera?.eventDetails?.ppeViolations === 0 &&
                              camera?.eventDetails?.catWalkViolations === 0);
                          else rigData
                        ">
                        <span class="bg-setting people-redzone"></span>
                        <strong>Red zone static</strong>
                      </span>
                      <ng-template #rigData>
                        <img
                          src="{{ getIcon(camera?.eventDetails) }}"
                          class="card-notations bg-image"
                          id="vi_accordian_dashboard_table_container_redzone" />
                        <span class="label">{{ getLabel(camera.eventDetails) }}</span>
                        <span matTooltip="{{ camera.eventDetails.rigState }}">: {{ camera.eventDetails.rigState }}</span>
                      </ng-template>
                    </p>
                  </mat-cell>
                </ng-container>

                <!-- Last seen online Definition -->
                <ng-container matColumnDef="lastSeenOnline">
                  <mat-header-cell *matHeaderCellDef> Last seen online </mat-header-cell>
                  <mat-cell *matCellDef="let data; let i = index" id="{{ 'vi_dashboard_grid_' + 'last_seen_online' + (i + 1) }}">
                    {{ getDateFormateString(data.lastSeenOnline) }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                  <mat-cell
                    *matCellDef="let data; let i = index"
                    id="{{ 'vi_dashboard_grid_' + 'action' + (i + 1) }}"
                    (click)="openCameraProfilePage(data)">
                    <mat-icon svgIcon="preview" class="preview-icon"></mat-icon>
                  </mat-cell>
                </ng-container>

                <!-- Header and Row Declarations -->
                <mat-header-row *matHeaderRowDef="['name', 'isRedzone', 'lastSeenOnline', 'action']"></mat-header-row>
                <mat-row
                  *matRowDef="let row; let i = index; columns: ['name', 'isRedzone', 'lastSeenOnline', 'action']"
                  (click)="onImageClick(row, i, rig); dashaborddrawer.open()"
                  id="vi_dashboard_grid_camera_profile_redirection"></mat-row>
              </mat-table>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-paginator
          *ngIf="totalRig"
          id="vi_dashboard_pagination"
          class="dashabord-pagination"
          #paginator
          [length]="totalRig"
          [showFirstLastButtons]="true"
          [pageSize]="alertFilter.recordsPerPage"
          (page)="onPageFired($event)"
          [pageSizeOptions]="[10, 20, 30]"
          showLabel="false">
        </mat-paginator>
      </ng-template>
    </div>
    <ng-template #gridLoader>
      <div class="date-filters-loader">
        <app-spinner></app-spinner>
      </div>
    </ng-template>
  </div>
</div>
<mat-drawer #dashaborddrawer class="example-sidenav" disableClose position="end">
  <app-event-popover
    *ngIf="popOverCameraDetails"
    [eventIndex]="selectedPopOverIndex"
    [hasNext]="hasNext"
    [hasPrevious]="hasPrevious"
    [cameraDetails]="popOverCameraDetails"
    (nextPrevEvent)="onEventClick($event, false)"
    (closeSideInfo)="dashaborddrawer.close(); popOverCameraDetails = null"></app-event-popover>
</mat-drawer>
<slb-toast> </slb-toast>
